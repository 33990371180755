// ANGULAR
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// OTHER
import { Observable } from 'rxjs';

// ACTIUM
import { Config } from '../config/env.config';
import { ActUpdateAuditRequest } from '../models/requests/update-audit-request';
import { ActCreateAuditRequest } from '../models';
import { ActAssignCorrectiveActionRequest } from '../models/requests/assign-corrective-action-request';

@Injectable()
export class AuditService {

    constructor(private httpClient: HttpClient) { }

    // ***** HTTP CALLS REGARDING AUDITS *****

    getAllAudits(): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/audit/allaudits`);
    }

    submitCreateAuditRequest(request: ActCreateAuditRequest): Observable<any> {
        return this.httpClient.post(`${Config.apiUrl}/audit`, request);
    }

    submitAssignCorrectiveActionsRequest(request: ActAssignCorrectiveActionRequest): Observable<any> {
        return this.httpClient.post(`${Config.apiUrl}/audit/correctiveaction`, request);
    }

    submitUpdateAuditRequest(request: ActUpdateAuditRequest): Observable<any> {
        return this.httpClient.post(`${Config.apiUrl}/audit/update`, request);
    }

    getAllAuditSummaries(): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/auditreporting`);
    }

    getAllUnfinishedAuditSummaries(): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/auditreporting/unfinished`);
    }

    getSpecificAudit(auditId: number): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/audit/specificAudit/${auditId}`);
    }

    /*
    Temp. call to get all users. This will ultimately need to be filtered to only display
    Audit users.
    TODO: Write SQL.
    */
    getAllAuditors(): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/actuser`);
    }

    /*
    Not exactly clear where the list of room types should come from as there are only two...
    PrimeNG is very particular about not accepting an array of string for the dropdown, there
    must be an array of object.
    */
    getAllRoomTypes(): string[] {
        const roomTypes: any[] = [{label: 'Salle de bain', value: 'Salle de bain'}, {label: 'Chambre', value: 'Chambre'}];
        return roomTypes;
    }

    /*
    This has no place here but in preparing for the demo I have to get something off the ground quickly.
     */
    getMobileBasicData(): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/basicdata`);
    }

    /*
    Likewise, this is mostly done for demo purposes and needs to be reconsidered.
    */
    advanceAuditStatus(auditId: number): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/audit/advance/${auditId}`);
    }

    cancelAudit(auditId: number): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/audit/cancel/${auditId}`);
    }

    // ***** END OF HTTP CALLS REGARDING AUDITS *****
}
