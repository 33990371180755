// ANGULAR
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// OTHER
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

// ACTIUM
import { SharedModule } from '../shared/shared.module';
import { AuthService } from 'src/app/services/auth.service';
import { ADTLoginRequest, TokenRequest } from 'src/app/models';

@Component({
    selector: 'app-adt-login',
    templateUrl: './adt-login.component.html',
    styleUrls: ['./adt-login.component.scss'],
})
export class AdtLoginComponent implements OnInit {
    public adtShiftInformationForm: FormGroup;
    public selectedShiftObject: any = null;
    public selectedRouteObject: any = null;
    public shifts: any[] = [];
    public routes: any[] = [];

    constructor(
        private ref: DynamicDialogRef,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router
    ) {
        this.adtShiftInformationForm = this.formBuilder.group({
            shiftObject: [this.selectedShiftObject, Validators.required],
            routeObject: [this.selectedRouteObject, Validators.required],
        });
    }

    ngOnInit(): void {
        this.getShifts();
    }

    /*
    Like elsewhere, there is a problem regarding my ability to pass around
    the authorized user. This is why I call the getter in authService. It works,
    but it is not the ideal way to do this.
    TODO: Import config from dialog service, and simply take the user
    that is passed on creation of this component.
    */
    async handleADTLogin(): Promise<void> {
        const currentUser = this.authService.currentUser;
        const adtLoginRequest: ADTLoginRequest = {
            userId: currentUser.userId,
            username: currentUser.username,
            establishmentId: currentUser.establishmentId,
            shift: this.adtShiftInformationForm.value.shiftObject.id,
            route: this.adtShiftInformationForm.value.routeObject.id,
        };
        try {
            await this.authService
                .initializeADTSpecificLogin(adtLoginRequest)
                .then((res: boolean) => {
                    this.router.navigateByUrl('/dashboard');
                    this.ref.close();
                });
        } catch (error) {
            this.handleFailedADTLogin();
        }
    }

    getShifts(): void {
        this.authService
            .getShifts()
            .subscribe(
                (relevantShifts: any[]) => (this.shifts = relevantShifts)
            );
    }

    getRoutes(shiftChangeEvent: any): void {
        this.authService
            .getRoutes(shiftChangeEvent.value.id)
            .subscribe(
                (relevantRoutes: any[]) => (this.routes = relevantRoutes)
            ); // shiftChangeEvent.value
    }

    handleFailedADTLogin(): void {
        console.log('Login failed');
    }
}
