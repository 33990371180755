import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workflowStatus'
})
export class WorkflowStatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case 0:
            ret = "Created"
            break;
        case 1:
            ret = "Initializing"
            break;
        case 2:
            ret = "Running"
            break;
        case 3:
            ret = "Paused"
            break;
        case 4:
            ret = "Pending"
            break;
        case 5:
            ret = "Completed"
            break;
        case 6:
            ret = "Cancelled"
            break;
        default:
            break;
    }
    return ret;
  }

}
