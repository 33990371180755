import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Config } from '../config/env.config';

@Injectable()
export class SseService {

  private source;
  private messageSource = new BehaviorSubject({});
  currentMessage = this.messageSource.asObservable();

  constructor() { 
    //console.log("sse.Service constructor");
    var _this = this;
    this.source = new EventSource(`${Config.sseUrl}?userName=jmdavid&groupName=admin&appName=adt`);
    this.source.onopen = function (e: any) {
        console.log(e);
    };
    this.source.onmessage = function (event: any) {
        //console.log("onmessage", event.data);
        _this.dispatchMessage(`onmessage: ${event.data}`);
    };
    this.source.addEventListener('task', function(event: any) {
        //console.log("task", event.data);
        _this.dispatchMessage(`task: ${event.data}`);
    });
    this.source.addEventListener('bed', function(event: any) {
        //console.log("bed", event.data);
        _this.dispatchMessage(`bed: ${event.data}`);
    });
  }

  dispatchMessage(message: any) {
    this.messageSource.next(message)
  }
}