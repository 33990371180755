// ANGULAR
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// ACTIUM
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module')
          .then((m) => m.AuthModule) },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module')
          .then((m) => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'instances', loadChildren: () => import('./modules/instances/instances.module')
          .then((m) => m.InstancesModule), canActivate: [AuthGuard] },
  { path: 'workflows/:id', loadChildren: () => import('./modules/workflows/workflows.module')
          .then((m) => m.WorkflowsModule), canActivate: [AuthGuard] },
  { path: 'routes', loadChildren: () => import('./modules/routes/routes.module')
          .then((m) => m.RoutesModule), canActivate: [AuthGuard] },
  { path: 'beds', loadChildren: () => import('./modules/beds/beds.module')
          .then((m) => m.BedsModule), canActivate: [AuthGuard] },
  { path: 'tasks', loadChildren: () => import('./modules/tasks/tasks.module')
          .then((m) => m.TasksModule), canActivate: [AuthGuard] },
  { path: 'reports', loadChildren: () => import('./modules/reports/reports.module')
          .then((m) => m.ReportsModule), canActivate: [AuthGuard] },
  { path: 'help', loadChildren: () => import('./modules/help/help.module')
          .then((m) => m.HelpModule), canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module')
          .then((m) => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'calendar', loadChildren: () => import('./modules/calendar/calendar.module')
          .then((m) => m.CalendarModule), canActivate: [AuthGuard] },
  { path: 'audit-table', loadChildren: () => import('./modules/audit-table/audit-table.module')
          .then((m) => m.AuditTableModule), canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
