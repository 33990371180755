// ANGULAR
import { Component, OnInit } from '@angular/core';

// OTHER
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

// ACTIUM
import { Infection } from 'src/app/models/infection';
import { ActInfectionStyle } from './infection-style';
import { InfectionDataService } from '../../../services/infection-service';

@Component({
    selector: 'app-create-infection',
    templateUrl: './create-infection.component.html',
    styleUrls: ['./create-infection.component.scss']
})
export class CreateInfectionComponent implements OnInit {

    public infection: Infection;
    public infectionStyle: ActInfectionStyle = {
        // color: '#' + this.config.data.color,
        color: '',
        backgroundColor: ''
    };

    constructor(private ref: DynamicDialogRef,
                private config: DynamicDialogConfig,
                private infectionService: InfectionDataService) { }

    ngOnInit(): void {
        this.infection = this.config.data.infection;
        this.infectionStyle.backgroundColor = '#' + this.infection.bgColor;
        this.infectionStyle.color = '#' + this.infection.color;
    }

    changeBgColor(event: any): void {
        this.infectionStyle.backgroundColor = event.value;
    }

    changeTextColor(event: any): void {
        this.infectionStyle.color = event.value;
    }

    updateInfection(): void {
        this.infection.color = this.infectionStyle.color.substring(1);
        this.infection.bgColor = this.infectionStyle.backgroundColor.substring(1);
        this.infectionService.updateInfection(this.infection, this.infection.id).subscribe((res: boolean) => {
            this.ref.close();
        });
    }
}
