import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';

import { Config } from '../config/env.config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    constructor(private httpClient: HttpClient) {}

    /*
    This is more than a little bit sketchy.
    TODO: Throughout the entire application we need to decide, once and for all,
    how we are going to handle dates. Dotnet has a great system, the DateTime class is sick,
    however, what will the TS send? I actually like sending strings and calling DateTime.Parse()
    on the backend... I will NOT change this now, however.
    */
    public listTasks(
        workflowId?: number,
        assignee?: string,
        rangeDates?: Date[]
    ) {
        if (rangeDates === null || rangeDates === undefined) {
            rangeDates = [];
            // Adding one day makes now = tomorrow and ensures that all active tasks will be caught
            const now = new Date();
            now.setDate(now.getDate() + 1);
            // In our case, lastWeek is a month ago - should reconsider default range?
            const lastWeek = new Date();
            lastWeek.setDate(now.getDate() - 30);
            // Attribute dates to rangeDates for submitting
            rangeDates[0] = lastWeek;
            rangeDates[1] = now;
        }
        // Now we have our two dates to pass to the server properly formatted
        const dateOne = formatDate(
            rangeDates[0],
            'yyyy/MM/dd hh:mm:ss',
            'en_US'
        );
        const dateTwo = formatDate(
            rangeDates[1],
            'yyyy/MM/dd hh:mm:ss',
            'en_US'
        );
        // Define filters
        let filters = `starttime BETWEEN '${dateOne}' AND '${dateTwo}'`;
        if (workflowId !== null && workflowId !== undefined) {
            filters += ` AND workflowid=${workflowId}`;
        }
        /*
        The following is commented because the web application will only be used by managers. This is to say
        that ALL TASKS, not tasks sorted by employee, should be displayed when viewing the web app. More directly,
        if the table of tasks were only to display those with the assignee of the logged in user, it will always be empty.
        I keep it here in case this idea changes.
        if (assignee !== null && assignee !== undefined) {
            filters += ` AND assignee='${assignee}'`;
        }
         */
        // Finally call API passing in filters populated by date range
        return this.httpClient.get(
            `${Config.apiUrl}/acttask?filters=${filters}&skip=0&limit=0&orderBy=id`
        );
    }

    public getTask(id) {
        return this.httpClient.get(`${Config.apiUrl}/acttask/${id}`);
    }

    // Passes relevant information to API to complete a task
    public completeTask(task: any, action: any, reason: any) {
        // Here we define the payload
        const body = {
            nodeName: task.name,
            action: action.name,
            assignee: task.assignee,
        };
        // In not all cases will a reason be required
        if (reason !== null && reason !== undefined) {
            // TODO: This is flagged as not existing as a property. Is it passed to the API properly?
            body['reasonId'] = reason.id;
        }
        // Finally call API passing in body of complete task request
        return this.httpClient.patch<any>(
            `${Config.apiUrl}/acttask/${task.id}/complete`,
            body
        );
    }

    public cancelTask(task: any) {
        return this.httpClient.get<any>(
            `${Config.apiUrl}/acttask/${task.id}/cancel`
        );
    }

    public reassignTask(task: any, assignee: any) {
        const body = {
            assignee: assignee.name,
        };
        return this.httpClient.patch<any>(
            `${Config.apiUrl}/acttask/${task.id}/reassign`,
            body
        );
    }

    public listReasons() {
        return this.httpClient.get(`${Config.apiUrl}/actreason/allreasons`); // WAS: (`${Config.apiUrl}/actutil/reasons`)
    }

    public listAvailableAssignees(spaceId: number) {
        return this.httpClient.get(
            `${Config.apiUrl}/actuser/availusers/${spaceId}`
        );
    }

    public notify() {
        return this.httpClient.get(`${Config.apiUrl}/sandbox/firebase`);
    }
}
