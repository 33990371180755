export enum CreateAuditSettingsType {
    Template,
    Date,
    Inspector,
    PredefinedSpace,
    Space,
    Level,
    Unit,
    Building,
    Establishment
}
