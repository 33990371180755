// ANGULAR
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpUserEvent,
} from '@angular/common/http';

// OTHER
import { Observable } from 'rxjs';

// ACTIUM
import { Config } from '../config/env.config';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
        | any
    > {
        return next.handle(
            this.addTokenToRequest(request, this.authService.accessToken)
        );
    }

    private addTokenToRequest(
        request: HttpRequest<any>,
        token: string
    ): HttpRequest<any> {
        return request.clone({
            setHeaders: { Authorization: `${Config.tokenType} ${token}` },
            setParams: { lang: 'fr' },
        });
    }
}
