// ANGULAR
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// OTHER
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActCreateGroupRequest } from 'src/app/models/requests/create-group-request';
import { forkJoin, Observable } from 'rxjs';

// ACTIUM
import { Group } from 'src/app/models/group';
import { ActUpdateGroupRequest } from 'src/app/models/requests/update-group-request';
import { GroupDataService } from '../../../services/group-data.service';
import { User } from '../../../models/user';
import { UserGroup } from '../../../models/usergroup';
import { UserDataService } from '../../../services/user-data.service';
import { Role } from '../../../models/role';
import { GroupRole } from '../../../models/grouprole';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {

  public dynamicGroupHeader: string = '';
  public mode: string = '';
  public group: Group = null;
  public accordianActiveState: boolean[] = [true, false, false];
  public groupInformationForm: FormGroup = null;

  // USER ASSOC PICKLIST REQUIRED ARRAYS
  public availableUsers: User[] = [];
  public pickListGroupAssociatedUsers: User[] = [];
  public allUsers: User[] = [];
  public allUserGroupByGroup: UserGroup[] = [];
  public finalUserGroupsToUpdate: UserGroup[] = [];

  // ROLE ASSOC PICKLIST REQUIRED ARRAYS
  public availableRoles: Role[] = [];
  public pickListGroupAssociatedRoles: Role[] = [];
  public allRoles: Role[] = [];
  public allGroupRolesByGroup: GroupRole[];
  public finalGroupRolesToUpdate: GroupRole[];

  constructor(public ref: DynamicDialogRef,
              private formBuilder: FormBuilder,
              private config: DynamicDialogConfig,
              private groupService: GroupDataService,
              private userService: UserDataService) {
    this.groupInformationForm = formBuilder.group({
      groupName: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.group = this.config.data.group;
    switch (this.group) {
      case null:
        this.mode = 'create';
        break;
      default:
        this.mode = 'edit';
        this.dynamicGroupHeader = ': ' + this.group.gName;
        this.populateGroupInformationForm(this.group);
        this.populateUserAssocPickList();
        this.populateRoleAssocPicklist();
        break;
    }
  }

  // ***** GROUP FORM RELATED FUNCTIONS *****

  populateGroupInformationForm(group: Group): void {
    this.groupInformationForm.patchValue({
      groupName: group.gName
    });
  }

  submitGroupInformationForm(): void {
    if (this.mode === 'create') { this.groupService.addNewGroup(this.handleCreateGroupRequest())
      .subscribe((createdGroup: Group) => {
        this.group = createdGroup;
        this.populateUserAssocPickList();
        this.populateRoleAssocPicklist();
      }); }
    if (this.mode === 'edit') {
      this.groupService.updateGroup1(this.handleUpdateGroupRequest())
        .subscribe((updatedGroup: Group) => {
          this.group = updatedGroup;
          this.dynamicGroupHeader = ': ' + this.group.gName;
      }); }
    this.accordianActiveState = [false, true, false];
  }

  handleUpdateGroupRequest(): ActUpdateGroupRequest {
    const formData = this.groupInformationForm.value;
    const updateGroupRequest: ActUpdateGroupRequest = {
      gName: formData.groupName,
      gId: this.group.gId
    };
    return updateGroupRequest;
  }

  handleCreateGroupRequest(): ActCreateGroupRequest {
    const formData = this.groupInformationForm.value;
    const createdGroupRequest: ActCreateGroupRequest = {
      gName: formData.groupName
    };
    return createdGroupRequest;
  }

  deleteGroup(): void {
    this.groupService.deleteGroup(this.group.gId).subscribe((res: boolean) => {
      this.ref.close();
    });
  }

  // ***** END OF GROUP FORM RELATED FUNCTIONS *****

  // ***** USER ASSOC PICKLIST RELATED FUNCTIONS *****

  submitUserAssocPicklist(): void {
    this.populateFinalUserGroupsToUpdate();
    this.groupService.updateUserGroupRelation(this.finalUserGroupsToUpdate, this.group.gId).subscribe((res: any) => {
      this.accordianActiveState = [false, false, true];
    });
  }

  private populateUserAssocPickList = () => {
    forkJoin(
      [
        this.populateAllUsers(),
        this.populateAllUserGroup()
      ]
    )
      .subscribe(([users, userGroups]) => {
        this.allUsers = users;
        this.allUserGroupByGroup = userGroups;
        this.populateAvailableUsers();
        this.populatePickListGroupAssociatedUsers();
      });
  }

  private populateAllUsers(): Observable<any> {
    return this.userService.populateUserTable();
  }

  private populateAllUserGroup(): Observable<any> {
    return this.groupService.getAllUserGroupsById(this.group.gId);
  }

  private populateAvailableUsers = () => {
    this.availableUsers = this.allUsers
      .filter(i1 => !this.allUserGroupByGroup
        .some(i2 => i1.id === i2.id));
  }

  private populatePickListGroupAssociatedUsers = () => {
    this.pickListGroupAssociatedUsers = this.allUsers
      .filter(i1 => this.allUserGroupByGroup
        .some(i2 => i1.id === i2.id));
  }

  private populateFinalUserGroupsToUpdate = () => {
    this.finalUserGroupsToUpdate = this.pickListGroupAssociatedUsers
      .map(({id}) => ({id, gId: this.group.gId}));
  }

  // ***** END OF USER ASSOC RELATED FUNCTIONS *****

  // ***** ROLE ASSOC RELATED FUNCTIONS *****

  submitRoleAssocPicklist(): void {
    this.populateFinalGroupRolesToUpdate();
    this.groupService.updateGroupRoleRelation(this.finalGroupRolesToUpdate, this.group.gId)
      .subscribe((res: any) => { this.ref.close(); });
  }

  private populateRoleAssocPicklist(): void {
    forkJoin(
      [
        this.populateAllRoles(),
        this.populateAllGroupRole()
      ]
    )
      .subscribe(([x, y]) => {
        this.allRoles = x;
        this.allGroupRolesByGroup = y;
        console.log(this.allGroupRolesByGroup);
        this.populateAvailableRoles();
        this.populatePickListGroupAssociatedRoles();
      });
  }

  private populateAllRoles(): Observable<any> {
    return this.groupService.getAllRoles();
  }

  private populateAllGroupRole(): Observable<any> {
    return this.groupService.getAllGroupRolesById(this.group.gId);
  }

  private populateAvailableRoles = () => {
    this.availableRoles = this.allRoles
      .filter(i1 => !this.allGroupRolesByGroup
        .some(i2 => i1.rId === i2.rId));
  }

  private populatePickListGroupAssociatedRoles = () => {
    this.pickListGroupAssociatedRoles = this.allRoles
      .filter(i1 => this.allGroupRolesByGroup
        .some(i2 => i1.rId === i2.rId));
  }

  private populateFinalGroupRolesToUpdate = () => {
    this.finalGroupRolesToUpdate = this.pickListGroupAssociatedRoles
      .map(({rId}) => ({rId, gId: this.group.gId}));
  }

  // ***** END OF ROLE ASSOC RELATED FUNCTIONS *****
}
