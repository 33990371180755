// ANGULAR
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

// ACTIUM
import {ActAttachment, ActAudit, ActQuestion, ActSection} from '../models';
import {hospitalLogo} from "../../assets/images/hospital-logo";


@Injectable()
export class AuditReportingService {

    constructor(private datePipe: DatePipe) { }

    getDocumentDefinitionAtp(currentAudit: ActAudit): any {
        const generatedTime = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
        const auditTime = this.datePipe.transform(new Date(currentAudit.plannedTime), 'MM/dd/yyyy')
            + ' '
            + this.datePipe.transform(new Date(currentAudit.plannedTime), 'shortTime');
        return {
            content: [
                { image: hospitalLogo, width: 150 },
                { text: '\n' },
                {
                    text: `Rapport généré le: ${generatedTime}
                    ID: ${currentAudit.auditId}
					Inspecteur: ${currentAudit.inspector}
					Auditeur: ${currentAudit.auditor}
					Établissement: ${currentAudit.establishment.name}
					Bâtiment: ${currentAudit.building.name}
					Niveau: ${currentAudit.level.name}
					Unité: ${currentAudit.space.unit.name}
					Numéro de porte: ${currentAudit.space.doorNumber}
					Type d'espace: ${currentAudit.space.predefinedSpace}
                    Score moyen: ${this.calculateAverageScore(currentAudit)}
                    Date d'exécution: ${auditTime}
                    Type d'inspection: ATP

                    Questions:`,
                },
                // this.generateMarkingQuestionsAndAnswers(currentAudit),
                this.generateAtpQuestionsAndAnswers(currentAudit),
                this.generateMarkingPictures(currentAudit)
            ],
            info: { title: `Audit ${currentAudit.auditId}` },
            styles: { testStyle: { fontSize: 18, border: 1 } }
        };
    }

    getDocumentDefinitionMarking(currentAudit: ActAudit): any {
        const generatedTime = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
        const auditTime = this.datePipe.transform(new Date(currentAudit.plannedTime), 'MM/dd/yyyy')
            + ' '
            + this.datePipe.transform(new Date(currentAudit.plannedTime), 'shortTime');
        return {
            content: [
                { image: hospitalLogo, width: 150 },
                /*
                This is left here to demonstrate how to add styles and face.
                {
                    text: `
                    Document officiel du Gouvernement du Québec`,
                    bold: true,
                    style: 'testStyle'
                },

                 */
                { text: '\n' },
                {
                    text: `Rapport généré le: ${generatedTime}
                    ID: ${currentAudit.auditId}
					Inspecteur: ${currentAudit.inspector}
					Auditeur: ${currentAudit.auditor}
					Établissement: ${currentAudit.establishment.name}
					Bâtiment: ${currentAudit.building.name}
					Unité: ${currentAudit.space.unit.name}
					Numéro de porte: ${currentAudit.space.doorNumber}
					Type d'espace: ${currentAudit.space.predefinedSpace}
                    Score moyen: ${this.calculateAverageScore(currentAudit)}
                    Date d'exécution: ${auditTime}
                    Type d'inspection: MARQUAGE

                    Questions:`,
                },
                this.generateMarkingQuestionsAndAnswers(currentAudit),
                // { image: this.generatePicturesTest(currentAudit), width: 150 },
                this.generateMarkingPictures(currentAudit)
            ],
            info: { title: `Audit ${currentAudit.auditId}` },
            styles: { testStyle: { fontSize: 18, border: 1 } }
        };
    }

    getDocumentDefinitionVisual(currentAudit: ActAudit): any {
        const generatedTime = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
        // const auditTime = this.datePipe.transform(new Date(currentAudit.plannedTime), 'MM/dd/yyyy');
        const auditTime = this.datePipe.transform(new Date(currentAudit.plannedTime), 'MM/dd/yyyy')
            + ' '
            + this.datePipe.transform(new Date(currentAudit.plannedTime), 'shortTime');

        return {
            content: [
                { image: hospitalLogo, width: 150 },
                { text: '\n' },
                {
                    text: `Rapport généré le: ${generatedTime}
                    ID: ${currentAudit.auditId}
					Inspecteur: ${currentAudit.inspector}
					Auditeur: ${currentAudit.auditor}
					Établissement: ${currentAudit.establishment.name}
					Bâtiment: ${currentAudit.building.name}
					Unité: ${currentAudit.space.unit.name}
					Numéro de porte: ${currentAudit.space.doorNumber}
					Type d'espace: ${currentAudit.space.predefinedSpace}
                    Score moyen: ${this.calculateAverageScore(currentAudit)}
                    Date d'exécution: ${auditTime}
                    Type d'inspection: VISUEL

                    Sections:`,
                },
                this.generateSectionsList(currentAudit),
                {
                    text: `
                    `
                },
                this.generateQuestionsAndAnswers(currentAudit),
                {
                    text: `




                    `
                },
                // { image: this.generatePicturesTest(currentAudit), width: 150 },
                this.generateVisualAndWorkPictures(currentAudit)
            ],
            info: { title: `Audit ${currentAudit.auditId}` },
            styles: { testStyle: { fontSize: 18, border: 1 } }
        };
    }

    getDocumentDefinitionWork(currentAudit: ActAudit): any {
        const generatedTime = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
        // const auditTime = this.datePipe.transform(new Date(currentAudit.plannedTime), 'MM/dd/yyyy');
        const auditTime = this.datePipe.transform(new Date(currentAudit.plannedTime), 'MM/dd/yyyy')
            + ' '
            + this.datePipe.transform(new Date(currentAudit.plannedTime), 'shortTime');

        return {
            content: [
                { image: hospitalLogo, width: 150 },
                { text: '\n' },
                {
                    text: `Rapport généré le: ${generatedTime}
                    ID: ${currentAudit.auditId}
					Inspecteur: ${currentAudit.inspector}
					Auditeur: ${currentAudit.auditor}
					Établissement: ${currentAudit.establishment.name}
					Bâtiment: ${currentAudit.building.name}
					Unité: ${currentAudit.space.unit.name}
					Numéro de porte: ${currentAudit.space.doorNumber}
					Type d'espace: ${currentAudit.space.predefinedSpace}
                    Score moyen: ${this.calculateAverageScore(currentAudit)}
                    Date d'exécution: ${auditTime}
                    Type d'inspection: TRAVAIL

                    Sections:`,
                },
                this.generateSectionsList(currentAudit),
                {
                    text: `
                    `
                },
                this.generateQuestionsAndAnswers(currentAudit),
                {
                    text: `




                    `
                },
                // { image: this.generatePicturesTest(currentAudit), width: 150 },
                this.generateVisualAndWorkPictures(currentAudit)
            ],
            info: { title: `Audit ${currentAudit.auditId}` },
            styles: { testStyle: { fontSize: 18, border: 1 } }
        };
    }

    calculateAverageScore(currentAudit: ActAudit): number {
        let totalQuestions: number = 0;
        let totalScore: number = 0;
        currentAudit.sections.forEach((section: ActSection) => {
            section.questions.forEach((question: ActQuestion) => {
                totalQuestions++;
                totalScore += question.answer;
            });
        });
        return Math.round((totalScore / totalQuestions) * 10);
    }

    generateAtpQuestionsAndAnswers(currentAudit: ActAudit): any {
        const tableArray: any[] = [];
        currentAudit.sections[0].questions.forEach((question: ActQuestion) => {
            // tableArray.push({ location: question.shape.itemLocation, answer: question.answer });
            tableArray.push(question);
        });
        return {
            table: {
                widths: ['*', '*', '*', '*'],
                body: [
                    [{ text: 'Lieu' },
                        { text: 'Réponse ATP initial' },
                        { text: 'Deuxième réponse ATP' },
                        { text: 'Réponse totale' }
                    ],
                    ...tableArray.map((question: ActQuestion) => {
                        return [{ text: question.shape.itemLocation },
                            { text: question.initialAtpAnswer },
                            { text: question.atpAnswer },
                            { text: question.answer }
                        ];
                    })
                ]
            }
        };
    }

    generateMarkingQuestionsAndAnswers(currentAudit: ActAudit): any {
        const tableArray: any[] = [];
        currentAudit.sections[0].questions.forEach((question: ActQuestion) => {
            tableArray.push({ location: question.shape.itemLocation, answer: question.answer });
        });
        return {
            table: {
                widths: ['*', '*'],
                body: [
                    [{ text: 'Lieu' }, { text: 'Réponse' }],
                    ...tableArray.map((x: any) => {
                        return [{ text: x.location }, { text: x.answer }];
                    })
                ]
            }
        };
    }

    populateSectionTotals(section: ActSection): string {
        let sectionTotal = 0;
        let totalQuestions = 0;

        section.questions.forEach((question: ActQuestion) => {
            sectionTotal += question.answer;
            totalQuestions++;
        });
        // return Math.round(sectionTotal / totalQuestions);
        return 'Moyenne de la section: ' + Math.round(sectionTotal / totalQuestions).toString();
    }

    generateQuestionsAndAnswers(currentAudit: ActAudit): any {
        let sectionNumber = 1;
        const tableArray: any[] = [];
        currentAudit.sections.forEach((section: ActSection) => {
            tableArray.push({ name: `${sectionNumber}. ${section.name}`,
                answer: this.populateSectionTotals(section) });
            section.questions.forEach((question: ActQuestion) => {
                tableArray.push({ name: question.name, answer: question.answer });
            });
            sectionNumber++;
        });
        return {
            table: {
                widths: ['*', '*'],
                body: [
                    [{ text: 'Titre' }, { text: 'Réponse' }],
                    ...tableArray.map((x: any) => {
                        return [{ text: x.name }, { text: x.answer }];
                    })
                ]
            }
        };
    }

    generateMarkingPictures(currentAudit: ActAudit): any {
        const photoArray: any[] = [];
        currentAudit.sections.forEach((section: ActSection) => {
            section.questions.forEach((question: ActQuestion) => {
                /*
                Basic validation to ensure there are attachments to iterate over.
                Checking for length !== 0 fails as the null array has length 1...
                 */
                if (question.attachments.length !== 0 && question.attachments !== null && question.attachments[0] !== null) {
                    question.attachments.forEach((attachment: ActAttachment) => {
                        photoArray.push({text: question.shape.itemLocation, bold: true});
                        photoArray.push({image: attachment.base64, width: 150});
                    });
                }
            });
        });
        return photoArray;
    }

    generateVisualAndWorkPictures(currentAudit: ActAudit): any {
        const photoArray: any[] = [];
        currentAudit.sections.forEach((section: ActSection) => {
            section.questions.forEach((question: ActQuestion) => {
                /*
                Basic validation to ensure there are attachments to iterate over.
                Checking for length !== 0 fails as the null array has length 1...
                 */
                if (question.attachments.length !== 0 && question.attachments !== null && question.attachments[0] !== null) {
                    question.attachments.forEach((attachment: ActAttachment) => {
                        photoArray.push({ text: question.name, bold: true });
                        photoArray.push({ image: attachment.base64, width: 150 });
                    });
                }
            });
        });
        return photoArray;
    }

    generatePicturesTest(currentAudit: ActAudit): any {
        let image: any;
        currentAudit.sections.forEach(section => {
            section.questions.forEach(question => {
                question.attachments?.forEach(attachment => {
                    image = attachment.base64;
                });
            });
        });
        return image;
    }

    generateSectionsList(currentAudit: ActAudit): any {
        const sections: any[] = [];
        currentAudit.sections.forEach((section: any) => { sections.push(section.name); });
        return {
            ol: [
                ...sections
            ]
        };
    }
}
