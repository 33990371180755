// ANGULAR
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { Component, OnInit } from '@angular/core';

// PRIME NG
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

// ACTIUM
import {Establishment} from "../../../models/establishment";
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'app-establishment',
    templateUrl: './establishment.component.html',
    styleUrls: ['./establishment.component.scss']
})
export class EstablishmentComponent implements OnInit {

    public establishmentForm: FormGroup;
    public establishments: Establishment[] = [];
    public selectedEstablishment: Establishment = {
        id: 0,
        name: ''
    };

    constructor(private ref: DynamicDialogRef,
                private config: DynamicDialogConfig,
                private formBuilder: FormBuilder,
                private authService: AuthService)
    {
        this.establishmentForm = this.formBuilder.group({
            establishment: [this.selectedEstablishment, Validators.required]
        });
    }

    ngOnInit(): void {
        this.establishments = this.config.data.establishments;
    }

    public handleEstablishmentSelection(){
        const currentUser = this.authService.currentUser;
        currentUser.establishmentId = this.selectedEstablishment.id;
        this.authService.currentUser = currentUser;

        this.ref.close(true);
    }
}
