// ANGULAR
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// OTHER
import { Table } from 'primeng/table/table';
import { OverlayPanel } from 'primeng/overlaypanel/public_api';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

// ACTIUM
import { WorkflowsService } from 'src/app/services/workflows.service';
import { TasksService } from 'src/app/services/tasks.service';
import { MqService } from 'src/app/services/mq.service';

@Component({
    selector: 'app-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss']
})

export class WorkflowComponent implements OnInit {
    private mqMessageSubscription: Subscription;
    public workflow: any = {};
    public tasks = [];
    public task;
    public assignee;
    public statuses: any[];
    public workflowId: number;
    public workflowLoaded: boolean = false;
    public assignees: any[] = [];
    public accordionActiveState: boolean[] = [false, false, false];

    @ViewChild('dt', { static: false }) table: Table;
    @ViewChild('opassignee', { static: false }) opAssignee: OverlayPanel;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private workflowsService : WorkflowsService,
        private tasksService: TasksService,
        private mqService: MqService,
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,

    ) { }

    ngOnInit(): void {
        this.workflowId = this.config.data.workflowId;
        this.populateInitialData();
        this.mqMessageSubscription = this.mqService.currentMessage.subscribe((message: any) => {
            this.onMqMessage(message);
        });
    }

    ngOnDestroy() {
        this.mqMessageSubscription.unsubscribe();
    }

    private populateInitialData(): void {
        this.getWorkflow();
        this.listTasks();
        this.listTaskStatuses();
    }

    private getWorkflow(): void {
        this.workflowsService.getWorkflow(this.workflowId).subscribe((data: any) => {
            this.workflow = data;
        });
    }

    private listTasks(): void {
        this.tasksService.listTasks(this.workflowId, null, null).subscribe((data: any) => {
            this.tasks = data;
        });
    }

    onMqMessage(message: any) {
        if(message.msgType == 0) { //workflow message
            if(this.workflowId == message.WorkflowInstanceId) {
                this.workflowsService.getWorkflow(this.workflowId).subscribe((data: any) => {
                    this.workflow = data;
                });
            }
        }
        if(message.msgType == 1) { //task message
            this.tasksService.getTask(message.id).subscribe((data: any) => {
                let task = data;
                let idx = this.tasks.findIndex(obj => {
                    return obj.id === message.id;
                });
                if(idx != null && idx != undefined && idx >= 0) {
                    this.tasks.splice(idx, 1, task);
                    this.tasks = [...this.tasks];
                }
                if(idx < 0) {
                    this.tasks.push(task);
                    this.tasks = [...this.tasks];
                }
            });
        }
    }

    refreshData(){
        this.getWorkflow();
        this.listTasks();
    }

    listTaskStatuses() {
        this.workflowsService.listStatuses("task").subscribe((data: any[]) => {
            this.statuses = data;
        });
    }

    getTaskStatus(status) {
        let label = this.statuses.find(obj => {
            return obj.value === status;
        });
        return label.label;
    }

    onStatusChange(event) {
        if(event.value != null && event.value != undefined) {
            this.table.filter(event.value.value, 'taskStatus', 'equals');
        }
        else {
            this.table.filter(-1, 'taskStatus', 'gt');
        }
    }

    cancelTask(event, task: any) {
        this.tasksService.cancelTask(task).subscribe((data: any) => {
            this.refreshData();
        });
    }

    // ***** ASSIGNMENT *****

    reassignTask(event, task: any, location: any) {
        this.listAvailableAssignees();
        this.opAssignee.hide();
        this.task = task;
        this.opAssignee.show(event, event.currentTarget);
    }

    listAvailableAssignees() {
        this.tasksService.listAvailableAssignees(this.workflow.bed.actSpace.id).subscribe((data: any[]) => {
            let aAssignees = [];
            data.forEach((a) => {
                let declined = this.workflow.definition.declinedAssignees.findIndex(obj => {
                    return obj === a.username;
                });
                let abandoned = this.workflow.definition.abandonedAssignees.findIndex(obj => {
                    return obj === a.username;
                });
                let label = "";
                if(declined >= 0) {
                    label += "(D) ";
                }
                if(abandoned >= 0) {
                    label += "(A) ";
                }
                let assignee = {
                    id: a.id,
                    name: a.username,
                    label: label + a.username
                }
                aAssignees.push(assignee);
            });
            this.assignees = [...aAssignees];
        });
    }

    onAssigneeChange(event) { this.assignee = event.value; }

    onAssigneeOk() {
        if(this.assignee != null && this.assignee != undefined) {
            this.opAssignee.hide();
            this.tasksService.reassignTask(this.task, this.assignee).subscribe((data: any) => {
                this.refreshData();
            });
        }
    }

    public addAdditionalWorkers(e: Event) {
        this.workflowsService.increaseRequiredAssignees(this.workflowId).subscribe((res) => {
            this.accordionActiveState[2] = false;
        });
    }

    // ***** END OF ASSIGNMENT *****

    isWorkflowFinished(workflow: any): boolean {
        if (workflow.status === 5 || workflow.status === 6) { return true; };
    }
}
