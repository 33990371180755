import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { User } from '../models/user';
import { Group } from '../models/group';
import { UserGroup } from '../models/usergroup';
import { Role } from '../models/role';
import { Config } from '../config/env.config';
import { ActUpdateGroupRequest } from '../models/requests/update-group-request';

@Injectable({
  providedIn: 'root'
})
export class GroupDataService {
  constructor(private httpClient: HttpClient) {
  }
  // PUBLIC METHODS
  // NOTE: Changing all Configuration.groupApiURL/whatever to Config.apiUrl/actgroup/whatever.
  // POSSIBLE BREAKING CHANGE.
  public populateGroupTable = () => {
    return this.httpClient.get<Group[]>(`${Config.apiUrl}/actgroup/getallgroups`); // Configuration.groupApiURL}/getallgroups
  }
  public getAllUsers(): Observable<any> {
    return this.httpClient.get<User[]>(`${Config.apiUrl}/actgroup/getallusers`);
  }
  public getAllUserGroupsById(id): Observable<any> {
    return this.httpClient.get<UserGroup[]>(`${Config.apiUrl}/actgroup/getallusergroups/${id}`);
  }
  public updateUserGroupRelation(data, id): Observable<any> {
    return this.httpClient.post(`${Config.apiUrl}/actgroup/updateusergroups/${id}`, data as UserGroup);
  }
  public getSpecificGroupById(id): Observable<any> {
    return this.httpClient.get<Group>(`${Config.apiUrl}/actgroup/getspecificgroup/${id}`);
  }
  public addNewGroup(group): Observable<any> {
    return this.httpClient.post(`${Config.apiUrl}/actgroup/addgroup`, group as Group);
  }
  public updateGroup1(request: ActUpdateGroupRequest): Observable<any> {
    return this.httpClient.post(`${Config.apiUrl}/actgroup/updategroup/${request.gId}`, request);
  }

  public updateGroup(group, id): Observable<any> {
    return this.httpClient.post(`${Config.apiUrl}/actgroup/updategroup/${id}`, group as Group);
  }

  public deleteGroup(id): Observable<any> {
    return this.httpClient.delete(`${Config.apiUrl}/actgroup/deletegroup/${id}`);
  }
  public getAllRoles(): Observable<any> {
    return this.httpClient.get(`${Config.apiUrl}/actgroup/getallroles`);
  }
  public getSpecificRoleById(id): Observable<any> {
    return this.httpClient.get(`${Config.apiUrl}/actgroup/getsinglerole/${id}`);
  }
  public getAllGroupRolesById(id): Observable<any> {
    return this.httpClient.get(`${Config.apiUrl}/actgroup/getallroles/${id}`);
  }
  public updateGroupRoleRelation(role, id): Observable<any> {
    return this.httpClient.post(`${Config.apiUrl}/actgroup/updategrouproles/${id}`, role as Role);
  }
  public getAllRolesByGroup(groupId: number): Observable<any> {
    return this.httpClient.get(`${Config.apiUrl}/actgroup/getallrolesbygroup/${groupId}`);
  }
}
