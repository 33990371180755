// ANGULAR
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';

// OTHER
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

// ACTIUM
import { User } from '../../../models/user';
import { UserDataService } from '../../../services/user-data.service';
import { Group } from '../../../models/group';
import { UserGroup } from 'src/app/models/usergroup';
import { ActCreateUserRequest } from '../../../models/requests/create-user-request';
import { ActUpdateUserRequest } from 'src/app/models/requests/update-user-request';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  // TRANSLATION
  public lblEdit: string = '';
  public dynamicInformationHeader: string = '';

  public mode: string = '';
  public userInformationForm: FormGroup;
  public user: User = null;
  public accordianActiveState: boolean[] = [true, false];
  public selectedConfirmPassword: string = '';
  public togglePassword: boolean = false;

  // PICKLIST REQUIRED ARRAYS
  public availableGroups: Group[] = [];
  public pickListUserAssociatedGroups: Group[] = [];
  public allGroups: Group[] = [];
  public allUserGroupByUser: UserGroup[] = [];
  public finalUserGroupsToUpdate: UserGroup[] = [];

  private emailRegex: string = '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?';

  constructor(
    public userDataService: UserDataService,
    public router: Router,
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private translate: TranslateService) {
  }

  /*
  Must refactor the switch, complete waste of space for what could be a simple ternary... It does work though
  */
  ngOnInit(): void {
    this.userInformationForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: ['', Validators.required],
      email: ['', Validators.email],
      username: ['', Validators.required],
      password: new FormControl(''),
      passwordConfirm: new FormControl ('')
    });
    this.handleTranslation();
    this.user = this.config.data.user;
    switch (this.user) {
      case null:
        this.mode = 'create';
        break;
      default:
        this.mode = 'edit';
        this.dynamicInformationHeader = ': ' + this.user.username;
        this.populateUserInformationForm(this.user);
        this.populatePickList();
        break;
    }
  }

  ngAfterViewInit(): void {
    document.getElementById('password-eye-icon').addEventListener('click', this.toggleTextPassword);
    document.getElementById('password-confirm-eye-icon').addEventListener('click', this.toggleTextPassword);
  }

  handleTranslation(): void {
    this.translate.get(['modules.userEdit.editUser'])
      .subscribe((translations: string[]) => {
        this.lblEdit = translations['modules.userEdit.editUser'];
      });
  }

  // ***** USER INFORMATION FORM RELATED FUNCTIONS *****

  populateUserInformationForm(user: User): void {
    this.userInformationForm.patchValue({
      firstName: user.firstname,
      lastName: user.lastname,
      username: user.username,
      email: user.email,
    });
  }

  submitUserInformationForm(): void {
    if (this.mode === 'create') { this.userDataService.addNewUser(this.handleCreateUserRequest())
      .subscribe((createdUser: User) => {
        this.user = createdUser;
        this.populatePickList();
    }); }
    if (this.mode === 'edit') { this.userDataService.updateUser(this.handleUpdateUserRequest())
      .subscribe((updatedUser: User) => {
        this.user = updatedUser;
        this.populatePickList();
        this.dynamicInformationHeader = ': ' + this.user.username;
        /*
        TODO: I leave the below because it seems like it should be able to dynamically change
        the header from within the overlay. The ideal result is that when we change the username
        within the overlay, the header is updated. I will simply remove the username from the header
        for now so at least there is no incorrect information.
        this.config.header = this.lblEdit + ': ' + this.user.username;
        */
      });
    }
    this.accordianActiveState = [false, true];
  }

  handleCreateUserRequest(): ActCreateUserRequest {
    const formData = this.userInformationForm.value;
    const createdUserRequest: ActCreateUserRequest = {
      username: formData.username,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password
    };
    return createdUserRequest;
  }

  handleUpdateUserRequest(): ActUpdateUserRequest {
    const formData = this.userInformationForm.value;
    const updateUserRequest: ActUpdateUserRequest = {
      username: formData.username,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      id: this.user.id
    };
    return updateUserRequest;
  }

  deleteUser = () => {
    this.userDataService.deleteUser(this.user.id).subscribe((res: boolean) => {
      this.ref.close();
    });
  }

  // ***** END OF USER INFORMATION FORM RELATED FUNCTIONS *****

  // ***** PICKLIST RELATED FUNCTIONS *****

  public populatePickList(): void {
    forkJoin(
      [
        this.populateAllGroups(),
        this.populateAllUserGroup()
      ]
    )
      .subscribe(([groups, userGroups]) => {
        this.allGroups = groups;
        this.allUserGroupByUser = userGroups;
        this.populateAvailableGroups();
        this.populatePickListUserAssociatedGroups();
      });
  }

  public submitPicklist(): void {
    this.populateFinalUserGroupsToUpdate();
    this.userDataService.updateUserGroupRelation(this.finalUserGroupsToUpdate, this.user.id).subscribe((res: any) => {
      this.ref.close();
    });
  }

  private populateFinalUserGroupsToUpdate(): void {
    this.finalUserGroupsToUpdate = this.pickListUserAssociatedGroups
      .map(({gId}) => ({gId, id: this.user.id}));
  }

  private populateAllGroups(): Observable<any> {
    return this.userDataService.getAllGroups();
  }

  private populateAllUserGroup(): Observable<any> {
    return this.userDataService.getAllUserGroupsById(this.user.id);
  }

  // ***** QUARANTINE *****

  /*
  The two functions "under quarantine" work but are not clear.
  These are good candidates for revision as they rely upon the strange
  'g' prefix. Do not touch for now... they do work properly.
  NOTE: I changed these functions in group-table to display the roles. If we
  have the right data types, we can rewrite this in a much more descriptive way
  to pass the TS compiler.
  */
  private populateAvailableGroups(): void {
    this.availableGroups = this.allGroups
      .filter(i1 => !this.allUserGroupByUser
        .some(i2 => i1.gId === i2.gId));
  }

  private populatePickListUserAssociatedGroups(): void {
    this.pickListUserAssociatedGroups = this.allGroups
      .filter(i1 => this.allUserGroupByUser
        .some(i2 => i1.gId === i2.gId));
  }

  // ***** END OF QUARANTINE *****

  // ***** END OF PICKLIST RELATED FUNCTIONS *****

  // ***** CUSTOM VALIDATION *****

  public confirmPasswordsMatch(confirmPassword: string, password: string): void {
    const formData = this.userInformationForm.value;
    const formControls = this.userInformationForm.controls;
    if (confirmPassword !== password) {
      formControls.password.setErrors({ incorrect: true });
      formControls.passwordConfirm.setErrors({ incorrect: true });
    }
    else {
      this.userInformationForm.setErrors(null);
      formControls.password.setErrors(null);
      formControls.passwordConfirm.setErrors(null);
    }
  }

  public toggleTextPassword(): void {
    const passwordField = document.getElementById('password');
    const confirmField = document.getElementById('passwordConfirm');
    const passwordIcon = document.getElementById('password-eye-icon');
    const confirmIcon = document.getElementById('password-confirm-eye-icon');
    this.togglePassword = !this.togglePassword;
    if (this.togglePassword) {
      passwordField.setAttribute('type', 'text');
      confirmField.setAttribute('type', 'text');
      passwordIcon.className = 'adt-icon icon-eye';
      confirmIcon.className = 'adt-icon icon-eye';
    }
    else {
      passwordField.setAttribute('type', 'password');
      confirmField.setAttribute('type', 'password');
      passwordIcon.className = 'adt-icon icon-eye-slash';
      confirmIcon.className = 'adt-icon icon-eye-slash';
    }
  }

  // ***** END OF CUSTOM VALIDATION *****
}
