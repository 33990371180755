// ANGULAR
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// OTHER
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// ACTIUM
import { User } from '../models/user';
import { Config } from '../config/env.config';
import { Group } from '../models/group';
import { UserGroup } from '../models/usergroup';
import { Infection } from '../models/infection';
import { Reason } from '../models/reasons';
import { ActUpdateUserRequest } from '../models/requests/update-user-request';

@Injectable({
    providedIn: 'root'
})
export class UserDataService {

    constructor(private httpClient: HttpClient) {
    }

    // PUBLIC METHODS
    // NOTE: Changing all Configuration.groupApiURL/whatever to Config.apiUrl/actgroup/whatever. Err... not exactly...
    // POSSIBLE BREAKING CHANGE.
    public populateUserTable = () => {
        return this.httpClient.get<User[]>(`${Config.apiUrl}/actuser`); // TODO: Test integration with JM ActUserService (`${Configuration.userApiURL}/getallusers`)
    }

    public getAllGroups(): Observable<any> {
        return this.httpClient.get<Group[]>(`${Config.apiUrl}/actuser/getallgroups`);
    }

    public getAllUserGroupsById(id): Observable<any> {
        return this.httpClient.get<UserGroup[]>(`${Config.apiUrl}/actuser/getallusergroups/${id}`);
    }

    public updateUserGroupRelation(data, id): Observable<any> {
        return this.httpClient.post(`${Config.apiUrl}/actuser/updateusergroups/${id}`, data as UserGroup);
    }

    public getSpecificUserById(id): Observable<any> {
        return this.httpClient.get<User>(`${Config.apiUrl}/actuser/getspecificuser/${id}`); // TODO: Test integration with JM ActUserService getspecificuser/
    }

    public addNewUser(user): Observable<any> {
        return this.httpClient.post(`${Config.apiUrl}/actuser`, user as User); // WAS: (`${Config.apiUrl}/actuser/addnewuser`, user as User)
    }

    public updateUser(request: ActUpdateUserRequest): Observable<any> {
        return this.httpClient.patch(`${Config.apiUrl}/actuser`, request);
    }

    public deleteUser(id): Observable<any> {
        return this.httpClient.delete(`${Config.apiUrl}/actuser/deleteuser/${id}`);
    }

    public getAllUsers(): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/actuser`);
    }

    public getEstablishments(id: number): Observable<any> {
        return this.httpClient.get(`${Config.apiUrl}/actuser/establishment/${id}`)
    }
}
