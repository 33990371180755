import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskStatusColor'
})
export class TaskStatusColorPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case 0:
            ret = "PaleGreen" //running
            break;
        case 1:
            ret = "Coral" //paused
            break;
        case 2:
            ret = "Gainsboro" //cancelled
            break;
        case 3:
            ret = "SlateBlue" //reassigned
            break;
        case 4:
            ret = "LimeGreen" //completed
            break;
        default:
            break;
    }
    return ret;
  }

}
