import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'auditInspectionTypeIcon'
})
export class AuditInspectionTypeIconPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        switch (value) {
            case 1:
                return 'adt-icon icon-flashlight';
            case 2:
                return 'adt-icon icon-eye';
            case 3:
                return 'adt-icon icon-vial';
            default:
                break;
        }
    }

}
