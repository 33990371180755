// ANGULAR
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ACTIUM
import { Config } from '../config/env.config';

@Injectable({
  providedIn: 'root'
})
export class BedsService {

    constructor(private httpClient: HttpClient) { }

    public listBeds(){
  		  return this.httpClient.get(`${Config.apiUrl}/actbed`); // ?orderby=b.currentworkflowid
    }

    public getBed(id: number){
		    return this.httpClient.get(`${Config.apiUrl}/actbed/${id}`);
    }

    public listPriorities(){
        return this.httpClient.get(`${Config.apiUrl}/actutil/priorities`);
    }

    public listInfections(){
        return this.httpClient.get(`${Config.apiUrl}/actinfection/infections`); // WAS: (`${Config.apiUrl}/actutil/infections`)
    }

    public updateBed(bed: any) {
        return this.httpClient.patch(`${Config.apiUrl}/actbed`, bed);
    }

    public updateBeds(beds: any) {
        return this.httpClient.patch(`${Config.apiUrl}/actbed/batch`, beds);
    }

    public getAllUnits() {
        return this.httpClient.get(`${Config.apiUrl}/actbed/getallunits`);
    }
}
