import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workflowWarningColor'
})
export class WorkflowWarningColorPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case 0:
            ret = "white" 
            break;
        case 1:
            ret = "yellow"
            break;
        case 2:
            ret = "orange" 
            break;
        case 3:
            ret = "red"
            break;
        default:
            break;
    }
    return ret;
  }

}