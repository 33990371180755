import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workflowStatusIcon'
})
export class WorkflowStatusIconPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case 0:
            ret = "adt-icon icon-stop" //created
            break;
        case 1:
            ret = "adt-icon icon-eject" //Initializing
            break;
        case 2:
            ret = "adt-icon icon-play" //Running
            break;
        case 3:
            ret = "adt-icon icon-pause" //paused
            break;
        case 4:
            ret = "adt-icon icon-to-start" //pending
            break;
        case 5:
          ret = "adt-icon icon-to-end" //completed
              break;
        case 6:
          ret = "adt-icon icon-ban" //cancelled
          break;
        default:
            break;
    }
    return ret;
  }
}