// ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeFrCa from '@angular/common/locales/fr-CA';

// OTHER
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmationService } from 'primeng/api';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';

registerLocaleData(localeFrCa, 'fr_CA');
export const createTranslationLoader = (httpClient: HttpClient) => {
    return new TranslateHttpLoader(httpClient as any, 'assets/i18n/', '.json');
};

// ACTIUM
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdtRxStompConfig } from './config/env.config';
import { AuthService } from './services/auth.service';
import { MqService } from './services/mq.service';
import { SseService } from './services/sse.service';
import { StorageService } from './services/storage.service';
import { TokenInterceptor } from './guards/token.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { AuditService } from './services/audit-service';
import { AuditReportingService } from "./services/audit-reporting.service";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslationLoader),
                deps: [HttpClient]
            }
        })
    ],
    entryComponents: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: InjectableRxStompConfig,
            useValue: AdtRxStompConfig
        },
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
            deps: [InjectableRxStompConfig]
        },
        AuthService,
        MqService,
        SseService,
        StorageService,
        ConfirmationService,
        AuditService,
        AuditReportingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
