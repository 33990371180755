import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskStatusIcon'
})
export class TaskStatusIconPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    var ret: string = "";
    switch (value) {
      case 0:
        ret = "adt-icon icon-play" //Running
        break;
      case 1:
        ret = "adt-icon icon-pause" //paused
        break;
      case 2:
        ret = "adt-icon icon-ban" //cancelled
        break;
      /*
      TODO:
      Missing icon for reassigned... if that even makes sense...
      */
      case 3:
          ret = '';
        break;
      case 4:
        ret = "adt-icon icon-to-end" //completed
        break;
      default:
        break;
    }
    return ret;
  }
}