import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workflowWarningIcon'
})
export class WorkflowWarningIconPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case 0:
            ret = "" 
            break;
        case 1:
            ret = "adt-icon icon-exclamation-triangle"
            break;
        case 2:
            ret = "adt-icon icon-exclamation-triangle" 
            break;
        case 3:
            ret = "adt-icon icon-exclamation-circle"
            break;
        default:
            break;
    }
    return ret;
  }

}