import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nodeStatusColor'
})
export class NodeStatusColorPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case 0:
            ret = "AntiqueWhite" //created
            break;
        case 1:
            ret = "BlanchedAlmond" //Initializing
            break;
        case 2:
            ret = "PaleGreen" //Running
            break;
        case 3:
            ret = "Chocolate" //paused
            break;
        case 4:
            ret = "Coral" //pending
            break;
        case 5:
          ret = "LimeGreen" //completed
              break;
        case 6:
          ret = "DarkGrey" //cancelled
          break;
        default:
            break;
    }
    return ret;
  }

}
