// ANGULAR
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// OTHER
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

// ACTIUM
import { Config } from '../config/env.config';
import { AuthUser } from '../models/act-user';
import { TokenResponse } from '../models/responses/token-response';
import { TokenRequest } from '../models/tokens';
import { StorageService } from './storage.service';
import { ADTLoginRequest } from '../models';
import { TokenStatus } from '../models/enums/token-status-enum';

// LOCAL STORAGE
const AUTHENTICATED_KEY = 'AUTHENTICATED';
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const TOKEN_EXPIRATION_KEY = 'TOKEN_EXPIRATION';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
const CURRENT_USER_KEY = 'CURRENT_USER';

@Injectable()
export class AuthService {
    // ***** PUBLIC GETTERS AND SETTERS USING LOCAL STORAGE *****

    public get isAuthenticated(): boolean {
        return this.storageService.getBooleanValue(AUTHENTICATED_KEY);
    }

    public set isAuthenticated(val: boolean) {
        this.storageService.setBooleanValue(AUTHENTICATED_KEY, val);
    }

    public get accessToken(): string {
        return this.storageService.getStringValue(ACCESS_TOKEN_KEY);
    }

    public set accessToken(val: string) {
        this.storageService.setStringValue(ACCESS_TOKEN_KEY, val);
    }

    public get tokenExpiration(): number {
        return this.storageService.getNumberValue(TOKEN_EXPIRATION_KEY);
    }

    public set tokenExpiration(val: number) {
        this.storageService.setNumberValue(TOKEN_EXPIRATION_KEY, val);
    }

    public get refreshToken(): string {
        return this.storageService.getStringValue(REFRESH_TOKEN_KEY);
    }

    public set refreshToken(val: string) {
        this.storageService.setStringValue(REFRESH_TOKEN_KEY, val);
    }

    public get currentUser(): AuthUser {
        return this.storageService.getObject(CURRENT_USER_KEY);
    }

    public set currentUser(val: AuthUser) {
        this.storageService.setObject(CURRENT_USER_KEY, val);
    }

    public get isADTUser(): boolean {
        return localStorage.getItem('CURRENT_USER').includes('ADT');
    }

    public get isAUDUser(): boolean {
        return localStorage.getItem('CURRENT_USER').includes('AUD');
    }

    // ***** END OF PUBLIC GETTERS AND SETTERS *****

    // ***** PRIMARY REFRENCE FOR USER STATUS *****

    // GENERAL AUTH
    isAuthSource = new BehaviorSubject(false);
    isAuthObservable = this.isAuthSource.asObservable();

    /*
    I believe, from reading the Angular docs, that the best way
    to do what I try to achieve here is really with route guards. That being said,
    this is a relatively quick and dirty way of ensuring that only certain
    menu items are available. It does NOT prevent entering the url manually.
    */
    // ADT USER
    isADTUserSource = new BehaviorSubject(false);
    isADTUserObservable = this.isADTUserSource.asObservable();

    // AUDIT USER
    isAUDUserSource = new BehaviorSubject(false);
    isAUDUserObservable = this.isAUDUserSource.asObservable();

    // ***** END OF USER STATUS REFERENCE *****

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private router: Router
    ) {}

    // ***** PRIMARY LOGIN FUNCTIONS *****

    async login(tokenRequest: TokenRequest): Promise<TokenStatus> {
        try {
            return await this.loginApiCall(tokenRequest).then(
                (tokenResponse: TokenResponse) => {
                    // Handle user already logged in.
                    if (tokenResponse.loggedIn) {
                        return TokenStatus.LoggedIn;
                    }

                    // Handle establishment selection required.
                    if (tokenResponse.establishmentRequired) {
                        this.currentUser = {
                            permissions: [],
                            username: '',
                            clientId: tokenResponse.requestedUserClientId,
                        };
                        return TokenStatus.EstablishmentRequired;
                    }

                    const createdUser: AuthUser =
                        this.unpackTokenAndAttributeToUser(
                            tokenRequest,
                            tokenResponse
                        );
                    this.updateAuthInformation(createdUser, tokenResponse);
                    return TokenStatus.Continue;
                }
            );
        } catch (error) {
            console.log(error);
            return TokenStatus.Error;
        }
    }

    public refresh(): Observable<TokenResponse> {
        return this.http
            .post<TokenResponse>(`${Config.apiUrl}/acttoken/refresh`, {
                Token: this.accessToken,
                RefreshToken: this.refreshToken,
            })
            .pipe(
                tap((tokenResponse: TokenResponse) => {
                    const decoded: any = jwt_decode(tokenResponse.token);
                    this.isAuthenticated = true;
                    this.accessToken = tokenResponse.token;
                    this.tokenExpiration = Number(decoded.exp);
                    this.refreshToken = tokenResponse.refreshtoken;

                    return tokenResponse;
                })
            );
    }

    logout(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `${Config.tokenType} ${this.accessToken}`,
        });

        return this.http
            .post(
                `${Config.apiUrl}/acttoken/exit`,
                { username: this.currentUser.username },
                { headers }
            )
            .pipe(
                tap((data) => {
                    this.isAuthenticated = false;
                    this.isAuthSource.next(false);
                    this.isAUDUserSource.next(false);
                    this.isADTUserSource.next(false);
                    this.storageService.removeItem(AUTHENTICATED_KEY);
                    this.storageService.removeItem(ACCESS_TOKEN_KEY);
                    this.storageService.removeItem(TOKEN_EXPIRATION_KEY);
                    this.storageService.removeItem(REFRESH_TOKEN_KEY);
                    this.storageService.removeItem(CURRENT_USER_KEY);
                    this.router.navigate(['/auth']);
                    return data;
                })
            );
    }

    public ping(): Observable<void> {
        return this.http.get<void>(`${Config.apiUrl}/acttoken/ping`);
    }

    // ***** END OF PRIMARY LOGIN FUNCTIONS *****

    // ***** SECONDARY FUNCTIONS FOR LOGIN *****

    /*
    Returns all shifts
    TODO: Noted on ClickUp, the backend returns a large object with
    plenty of unnecessary data. Should only return id and name. Then we
    can create a return data type for type safety.
    */
    public getShifts(): Observable<any> {
        return this.http.get(`${Config.apiUrl}/actroute/shifts`);
    }

    /*
    Returns all routes associated with a selected shift.
    TODO: Like getShifts, what is returned is huge. Should simplify and
    create a return data type.
    */
    public getRoutes(id: number): Observable<any> {
        return this.http.get(`${Config.apiUrl}/actroute/shifts/${id}/routes`);
    }

    private unpackTokenAndAttributeToUser(
        tokenRequest: TokenRequest,
        tokenResponse: TokenResponse
    ): AuthUser {
        const decoded: any = jwt_decode(tokenResponse.token);
        this.tokenExpiration = Number(decoded.exp);
        const createdUser: AuthUser = {
            userId: tokenResponse.userid,
            firstName: decoded.FirstName,
            lastName: decoded.LastName,
            email: decoded.Email,
            isadmin: decoded.isAdmin,
            establishmentId: decoded.Establishment,
            permissions: JSON.parse(decoded.Permissions),
            username: tokenRequest.username,
        };
        return createdUser;
    }

    private updateAuthInformation(
        createdUser: AuthUser,
        tokenResponse: TokenResponse
    ): void {
        this.determineApplicationUser(createdUser);
        this.currentUser = createdUser;
        this.isAuthenticated = true;
        this.isAuthSource.next(true);
        this.accessToken = tokenResponse.token;
        this.refreshToken = tokenResponse.refreshtoken;
    }

    /*
    As noted on ClickUp, this whole system is not the most ideal manner of handling this problem.
    */
    private determineApplicationUser(createdUser: AuthUser): void {
        createdUser.permissions.forEach((permission) => {
            if (permission.RPermission.includes('ADT')) {
                this.isADTUserSource.next(true);
            }
            if (permission.RPermission.includes('AUD')) {
                this.isAUDUserSource.next(true);
            }
        });
    }

    async initializeADTSpecificLogin(
        adtRequest: ADTLoginRequest
    ): Promise<boolean> {
        try {
            this.adtLoginApiCall(adtRequest).subscribe((res: boolean) => res);
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    private loginApiCall(request: TokenRequest): Promise<any> {
        return this.http.post(`${Config.apiUrl}/acttoken`, request).toPromise();
    }

    private adtLoginApiCall(request: ADTLoginRequest): Observable<any> {
        return this.http.post(
            `${Config.apiUrl}/acttoken/initializeadt`,
            request
        );
    }

    // ***** END OF SECONDARY FUNCTIONS *****
}
