// ANGULAR
import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';

// OTHER
import { Observable } from 'rxjs';

// ACTIUM
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        try {
            await this.authService.ping().toPromise();
            return true;
        } catch (err) {}

        try {
            await this.authService.refresh().toPromise();
            return true;
        } catch (err) {}

        this.router.navigate(['/auth']);
        return false;
    }
}
