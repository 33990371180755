import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Config } from '../config/env.config';
import { Infection } from '../models/infection';

@Injectable({
  providedIn: 'root'
})
export class InfectionDataService {
  constructor(private httpClient: HttpClient) {
  }
  // PUBLIC METHODS
  public getAllInfections = () => {
    return this.httpClient.get<Infection[]>(`${Config.apiUrl}/actinfection/infections`);
  }
  public getSpecificInfection(id): Observable<any> {
    return this.httpClient.get(`${Config.apiUrl}/actinfection/singleinfection/${id}`);
  }
  public updateInfection(infection, id): Observable<any> {
    return this.httpClient.post(`${Config.apiUrl}/actinfection/updateinfection/${id}`, infection as Infection);
  }
  public addNewInfection(infection): Observable<any> {
    return this.httpClient.post(`${Config.apiUrl}/actinfection/addinfection`, infection as Infection);
  }
  public deleteInfection(id): Observable<any> {
    return this.httpClient.delete(`${Config.apiUrl}/actinfection/deleteinfection/${id}`);
  }
}
