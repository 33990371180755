import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nodeType'
})
export class NodeTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case 0:
            ret = "Début"
            break;
        case 1:
            ret = "Utilisateur"
            break;
        case 2:
            ret = "Système"
            break;
        case 3:
            ret = "Fin"
            break;
        default:
            break;
    }
    return ret;
  }

}
