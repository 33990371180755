import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    setStringValue(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    getStringValue(key: string, defaultValue?: string): string {
        if (localStorage.getItem(key) != null) {
            return localStorage.getItem(key);
        } else {
            return defaultValue;
        }
    }

    setBooleanValue(key: string, value: boolean): void {
        const valueStr = JSON.stringify(value);
        localStorage.setItem(key, valueStr);
    }

    getBooleanValue(key: string): boolean {
        return localStorage.getItem(key) === 'true';
    }

    setNumberValue(key: string, value: number): void {
        const valueStr = JSON.stringify(value);
        localStorage.setItem(key, valueStr);
    }

    getNumberValue(key: string, defaultValue?: number): number {
        if (localStorage.getItem(key) != null) {
            const valueStr = localStorage.getItem(key);
            return JSON.parse(valueStr);
        } else {
            return defaultValue;
        }
    }

    setObject<T>(key: string, value: T): void {
        const valueStr = JSON.stringify(value);
        localStorage.setItem(key, valueStr);
    }

    getObject<T>(key: string): T {
        const valueStr = localStorage.getItem(key);
        if (valueStr) {
            return JSON.parse(valueStr);
        } else {
            return undefined;
        }
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    clear(): void {
        localStorage.clear();
    }
}
