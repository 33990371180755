// ANGULAR
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// OTHER
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';

// ACTIUM
import { ActAudit, ActCreateAuditRequest, AuthUser } from 'src/app/models';
import { ActUpdateAuditRequest } from '../../models/requests/update-audit-request';
import { AuditService } from 'src/app/services/audit-service';
import { BedsService } from 'src/app/services/beds.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActAdvanceAuditStatusResponse } from '../../models/responses/advance-audit-status-response';
import { ActAdvanceAuditResponse } from '../../models/responses/advance-audit-response';
import { BasicDataResponse } from "../../models/responses/basic-data-response";
import { Establishment } from "../../models/establishment";
import { Building } from "../../models/building";
import { Level } from "../../models/level";
import { Unit } from "../../models/unit";
import { PredefinedSpace } from "../../models/predefined-space";
import { Space } from "../../models/space";
import { CreateAuditSettingsType } from "../../models/enums/create-audit-settings-type-enum";
import { UsernameDropdown } from "../../models/audit/username-dropdown";
import { ActCreateAuditResponse } from "../../models/responses/create-audit-response";
import { ActAssignCorrectiveActionRequest } from 'src/app/models/requests/assign-corrective-action-request';

@Component({ 
    selector: 'app-create-audit',
    templateUrl: './create-audit.component.html',
    styleUrls: ['./create-audit.component.scss']
})
export class CreateAuditComponent implements OnInit {

    public defaultDate: Date;
    public auditForm: FormGroup;
    public correctiveActionForm: FormGroup;
    public workflowDependent: boolean = false;
    public auditMode: string = '';
    public primaryAudit: any = {space: {doorNumber: ''}};
    private currentUser: AuthUser = null;
    public currentStatus: string = '';
    public nextAction: string = '';
    public auditTypeString: string = '';

    // BASIC DATA
    public establishments: Establishment[] = [];
    public buildings: Building[] = [];
    public levels: Level[] = [];
    public predefinedSpaces: PredefinedSpace[] = [];
    public spaces: Space[] = [];
    public units: Unit[] = [];
    public basicData: BasicDataResponse = null;

    // FORM OPTIONS ARRAYS
    public formEstablishments: Establishment[] = [];
    public formBuildings: Building[] = [];
    public formLevels: Level[] = [];
    public formSpaces: Space[] = [];
    public formUnits: Unit[] = [];
    public formUsernames: UsernameDropdown[] = [];
    public correctiveActionsFormUsernames: UsernameDropdown[] = [];

    // CORRECTIVE ACTIONS
    public correctiveActionInspector: string = '';

    constructor(private ref: DynamicDialogRef,
                private config: DynamicDialogConfig,
                private formBuilder: FormBuilder,
                private auditService: AuditService,
                private bedService: BedsService,
                private authService: AuthService,
                private confirmationService: ConfirmationService)
    {
        this.auditForm = formBuilder.group({
            templateName: [''],
            inspectionType: [''],
            plannedTime: [''],
            inspector: [''],
            predefinedSpace: [''],
            space: [''],
            level: [''],
            unit: [''],
            building: [''],
            establishment: [''],
            workflowDependent: ['']
        });
        this.correctiveActionForm = formBuilder.group({
            plannedTime: [''],
            inspector: ['']
        });
    }

    ngOnInit(): void {
        this.populateBasicData();
    }

    private populateBasicData(): void {
        this.auditService.getMobileBasicData().subscribe((basicData: BasicDataResponse) => {
            this.basicData = basicData;
            this.pageLoadWithData();
        }); 
    }

    private pageLoadWithData(): void {
        (this.config.data.auditId === 0) ? this.auditMode = 'create' : this.auditMode = 'edit';
        if (this.auditMode === 'edit') { this.getSpecificAuditAndPopulateForm(this.config.data.auditId); }
        if (this.auditMode === 'create') { this.auditForm.patchValue({ plannedTime: this.config.data.auditDate }); }
    }

    private getSpecificAuditAndPopulateForm(auditId: number): void {
        this.auditService.getSpecificAudit(auditId)
            .subscribe((audit: ActAudit) => {
                console.log(audit);
                
                this.primaryAudit = audit;
                this.populateEditAuditForm(audit);
                this.populateAuditStatusData();
            });
    }

    // TODO: Must reconsider how this works - not well done at all.
    populateAuditStatusData(): void {
        // Set auditTypeString
        switch (this.primaryAudit.inspectionType) {
            case 1:
                this.auditTypeString = 'MARQUAGE';
                break;
            case 2:
                this.auditTypeString = 'VISUELLE';
                break;
            case 3:
                this.auditTypeString = 'ATP';
                break;
            case 4:
                this.auditTypeString = 'TRAVAIL';
                break;
            default:
                break;
        }

        // Set audit status
        (this.primaryAudit.inspected)
            ? this.currentStatus = 'COMPLÉTÉ'
            : this.currentStatus = this.primaryAudit.status.toString();

        // Set next available action - STUPID WAY TO HANDLE THIS
        if (this.currentStatus === '0' && this.primaryAudit.inspected === false) {
            if (this.primaryAudit.inspectionType === 2) { this.nextAction = 'INSPECTER'; }
            if (this.primaryAudit.inspectionType !== 2) {
                (this.primaryAudit.marked)
                    ? this.nextAction = 'INSPECTER'
                    : this.nextAction = 'MARQUAGE';
            }
        }
    }

    populateEditAuditForm(audit: ActAudit): void {
        // Set options for corrective actions form.
        if (audit.status === 8) {
            this.correctiveActionForm.controls['plannedTime'].setValue(new Date());
            this.correctiveActionsFormUsernames = [];
            this.basicData.userStrings.forEach((user) => this.correctiveActionsFormUsernames.push({ 
                label: user, 
                value: user 
            }))
        }

        // Populate form arrays for patching.
        this.establishments.push(audit.establishment);
        this.formBuildings.push(audit.building);
        this.formLevels.push(audit.level);
        this.formSpaces.push(audit.space);
        this.formUnits.push(audit.space.unit);
        this.formUsernames.push({ label:audit.inspector, value: audit.inspector });
        this.workflowDependent = audit.workflowDependent;
        this.auditForm.patchValue({
            plannedTime: new Date(audit.plannedTime),
            inspector: this.formUsernames[0],
            establishment: this.establishments[0],
            building: this.formBuildings[0],
            level: this.formLevels[0],
            space: this.formSpaces[0],
            unit: this.formUnits[0],
            workflowDependent: audit.workflowDependent
        });
    }

    public handleChooseAuditType(auditType: number): void {
        this.primaryAudit.inspectionType = auditType;

        // Now that we have the audit type we can perform the first filtering async.
        this.populateFormData();
    }

    private populateFormData(): void {
        console.log('calling populateFormData');

        this.formUsernames = [];
        this.basicData.userStrings.forEach((user) => this.formUsernames.push({ label: user, value: user }))
        this.currentUser = this.authService.currentUser;

        this.defaultDate = new Date();
        console.log(this.defaultDate);

        this.auditForm.controls['plannedTime'].setValue(this.defaultDate);

        // Clear form in case any selections have been made.
        this.auditForm.controls['building'].setValue('');
        this.auditForm.controls['unit'].setValue('');
        this.auditForm.controls['level'].setValue('');
        this.auditForm.controls['space'].setValue('');

        console.log(this.basicData);

        switch (this.primaryAudit.inspectionType) {
            case 1:
                // Fall through to 3 as MARKING(1) AND ATP(3) are treated the same.
            case 3:
                const availablePredefinedSpaceIds = this.filterAvailablePredefinedSpaces();
                this.filterFromPredefinedSpaces(availablePredefinedSpaceIds);

                break;
            case 4:
                // Fall through to 2 as VISUAL(2) AND WORK(4) are treated the same.
            case 2:
                this.establishments = this.basicData.establishments;
                this.buildings = this.basicData.buildings;
                this.levels = this.basicData.levels;
                this.units = this.basicData.units;
                this.predefinedSpaces = this.basicData.predefinedSpaces;
                this.spaces = this.basicData.spaces;

                break;
        }
    }

    private filterAvailablePredefinedSpaces(): number[] {
        const availableSpaceIds: number[] = this.basicData.backgroundImages.map((img: any) => img.predefinedSpaceId);
        this.predefinedSpaces = this.basicData.predefinedSpaces.filter((predefinedSpace) => availableSpaceIds.includes(predefinedSpace.id));

        return availableSpaceIds;
    }

    private filterFromPredefinedSpaces(predefinedSpacesAvailable: number[],
                                       ignoreSpaceType: boolean = false): void {

        this.spaces = this.basicData.spaces.filter((space) => predefinedSpacesAvailable.includes(space.predefinedSpaceId));
        this.formSpaces = this.spaces;

        const unitIds = new Set<number>();
        this.spaces.forEach((space) => unitIds.add(space.unit.id));
        this.units = this.basicData.units.filter((unit) => unitIds.has(unit.id));

        const levelIds = new Set<number>();
        this.spaces.forEach((space) => levelIds.add(space.levelId));
        this.levels = this.basicData.levels.filter((level) => levelIds.has(level.id));

        const buildingIds = new Set<number>();
        this.levels.forEach((level) => buildingIds.add(level.buildingId));
        this.buildings = this.basicData.buildings.filter((building) => buildingIds.has(building.id));

        const establishmentIds = new Set<number>();
        this.buildings.forEach((building) => establishmentIds.add(building.establishmentId));
        this.establishments = this.basicData.establishments.filter((establishment) => establishmentIds.has(establishment.id));
    }

    public returnCreateAuditEnum(type: string): CreateAuditSettingsType { return CreateAuditSettingsType[type]; }

    /*
    This is the equivalent of closing the modal in Audit mobile.
    Update ensures that selections are cleared and options filters subsequent choices.
     */
    public handleSelectionChange(event: any, type: CreateAuditSettingsType): void {
        const formValue = this.auditForm.value;

        switch (type) {
            case CreateAuditSettingsType.Establishment :
                // Handle update
                if (formValue.building !== '') {
                    this.auditForm.value.building = '';
                    if (formValue.level !== '')
                        this.auditForm.value.level = '';
                    if (formValue.unit !== '')
                        this.auditForm.value.unit = '';
                }
                if (formValue.space !== '')
                    this.auditForm.value.space = '';
                // Handle options
                this.formBuildings = this.buildings.filter((building) =>
                    building.establishmentId === formValue.establishment.id);
                break;
            case CreateAuditSettingsType.Building :
                // Handle update
                if (formValue.level !== '')
                    this.auditForm.value.level = '';
                if (formValue.space !== '')
                    this.auditForm.value.space = '';
                if (formValue.unit !== '')
                    this.auditForm.value.unit = '';
                // Handle options
                this.formLevels = this.levels.filter((level) => level.buildingId == formValue.building.id);
                this.formUnits = this.units.filter((unit) => unit.buildingId == formValue.building.id);
                break;
            case CreateAuditSettingsType.Level :
                // Handle update
                if (formValue.space !== '')
                    this.auditForm.value.space = '';
                // Handle options
                this.formSpaces = this.spaces.filter((space) => space.levelId === formValue.level.id);
                break;
            case CreateAuditSettingsType.Unit :
                // Handle update
                if (formValue.space !== '')
                    this.auditForm.value.space = '';
                // Handle options
                // this.formSpaces = this.spaces.filter((space) => space. === formValue.level.id);
                this.formSpaces = this.spaces.filter((space) => space.unit.id == formValue.unit.id);
                break;
        }
    }

    submitCreateOrEditAudit(): void {
        switch (this.auditMode) {
            case 'create':
                this.handleCreateAuditRequest();
                break;
            case 'edit':
                this.handleEditAuditRequest();
                break;
            default:
                break;
        }
    }

    handleCreateAuditRequest(): void {
        const formData = this.auditForm.value;

        const request: ActCreateAuditRequest = {
            auditor: this.authService.currentUser.username,
            building: formData.building,
            establishment: formData.establishment,
            inspectionLocation: '',
            inspectionType: this.primaryAudit.inspectionType,
            inspector: formData.inspector.value,
            level: formData.level,
            plannedTime: new Date(formData.plannedTime),
            space: formData.space,
            templateName: '',
            workflowDependent: this.workflowDependent,
            unit: formData.unit
        }

        /*
        Close dialog regardless of response. If no audit is returned, display error message.
        If the response is a success and workflowDependent is false we immediately request a
        call to advance audit status in order to classify the audit as an active task. This
        mimics the functionality of the mobile app.
        */
        this.auditService.submitCreateAuditRequest(request)
            .subscribe((response: ActCreateAuditResponse) => {
                // If the response audit is null there is an error message and the action is incomplete.
                if (response.audit === null) {
                    this.confirmationService.confirm({
                        message: response.errorMessage,
                        accept: () => { this.ref.close(); }
                    });
                } else { this.handleSuccessfulCreation(response.audit); }
                this.ref.close();
            });
    }

    private handleSuccessfulCreation(audit: ActAudit): void {
        if (!audit.workflowDependent) { this.auditService.advanceAuditStatus(audit.auditId)
            .subscribe((advanceAuditResponse: ActAdvanceAuditResponse) => {
                // Like above, if the response audit is null there is an error message and the action is incomplete.
                if (advanceAuditResponse.audit === null) { this.confirmationService.confirm({
                    message: advanceAuditResponse.errorMessage,
                    accept: () => { this.ref.close(); }
                }); } }); }
    }

    handleEditAuditRequest(): void {
        const auditToEdit: ActAudit = this.attributeBasicInformation(this.primaryAudit);
        const editAuditRequest: ActUpdateAuditRequest = { auditId: this.primaryAudit.auditId, audit: auditToEdit };
        this.auditService.submitUpdateAuditRequest(editAuditRequest).subscribe((res: any) => { this.ref.close(); });
    }

    attributeBasicInformation(audit: ActAudit): ActAudit {
        const formData = this.auditForm.value;
        audit.auditId = this.primaryAudit.auditId;
        audit.name = formData.inspectionType.name;
        audit.inspectionType = this.primaryAudit.inspectionType;
        audit.sectorId = formData.sectorId;
        audit.name = formData.templateName;
        audit.roomId = formData.roomId;
        audit.plannedTime = formData.plannedTime;
        audit.auditor = this.primaryAudit.auditor;
        audit.inspector = formData.inspector;
        audit.workflowDependent = formData.workflowDependent;
        if (audit.inspectionType === 1) {audit.inspectionLocation = formData.inspectionLocation; }
        return audit;
    }

    /*
    Attempts to advance audit status manually (not controlled by completing/starting workflows.
    Will prompt the user with an error message if response.audit is null.
    Closes the dialog regardless of response.
     */
    public advanceAuditStatus(): void {
        this.auditService.advanceAuditStatus(this.primaryAudit.auditId)
            .subscribe((response: ActAdvanceAuditStatusResponse) => {
                if (response.audit == null) {
                    this.ref.close();
                    this.confirmationService.confirm({
                        message: response.errorMessage,
                        // Is this call to close redundant? Check above as well.
                        accept: () => { this.ref.close(); }
                    });
                } else { this.ref.close(); }
            });
    }

    public async cancelAudit(): Promise<void> {
        await this.auditService.cancelAudit(this.primaryAudit.auditId).toPromise().then((res: boolean) => {
            if (res) { this.ref.close(); }
        });
    }

    public handleAssignCAAudit(): void {
        const formData = this.correctiveActionForm.value;
        var request: ActAssignCorrectiveActionRequest = {
            auditId: this.primaryAudit.auditId,
            inspector: formData.inspector.value,
            plannedTime: new Date(formData.plannedTime)
        };

        console.log(request);

        this.auditService.submitAssignCorrectiveActionsRequest(request).subscribe((res: any) => {
            console.log(res);
            this.ref.close();
        })
    }
}