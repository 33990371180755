// ANGULAR
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';

// OTHER
import {Observable} from "rxjs";

// ACTIUM
import { Config } from '../config/env.config';
import {ActStartWorkflowRequest} from "../models/requests/start-workflow-request";

@Injectable({
    providedIn: 'root'
})
export class WorkflowsService {

    constructor(private httpClient: HttpClient) { }

    public listWorkflows(rangeDates?: Date[]){
        if(rangeDates == null || rangeDates == undefined) {
            rangeDates = [];
            let now = new Date();
            now.setDate(now.getDate() + 1);
            let lastWeek = new Date();
            lastWeek.setDate(now.getDate() - 30);
            rangeDates[0] = lastWeek;
            rangeDates[1] = now;
        }
        let d1 = formatDate(rangeDates[0], "yyyy/MM/dd hh:mm:ss", "en_US");
        let d2 = formatDate(rangeDates[1], "yyyy/MM/dd hh:mm:ss", "en_US"); // like with the others, this doesn't work properly - it's 4:53pm and VS sees 04:53 tomorrow
        return this.httpClient.get(`${Config.apiUrl}/actworkflow?filters=createdAt BETWEEN '${d1}' AND '${d2}'&skip=0&limit=0&orderBy=id`);
    }

    public getWorkflow(id){
		return this.httpClient.get(`${Config.apiUrl}/actworkflow/${id}`);
    }

    public transitionWorkflow(id, workflowId, name, action, assignee){
        const body = {
            "nodeName": name,
            "action": action,
            "assignee": assignee
        };
        return this.httpClient.patch<any>(`${Config.apiUrl}/actworkflow/${workflowId}/transition`, body);
    }

    public reinit(){
        return this.httpClient.get(`${Config.apiUrl}/actutil/reinit`);
    }

    public createWorkflow(formData){
        return this.httpClient.post<any>(`${Config.apiUrl}/actworkflow`, formData);
    }

    public startWorkflow(request: ActStartWorkflowRequest): Observable<any> {
        return this.httpClient.post(`${Config.apiUrl}/actworkflow/start`, request);
    }

    public cancelWorkflow(id) {
        return this.httpClient.get(`${Config.apiUrl}/actworkflow/${id}/cancel`);
    }

    public listStatuses(type){
		return this.httpClient.get(`${Config.apiUrl}/actutil/statuses/${type}`);
    }

    public listWorkflowSummaries(rangeDates?: Date[]) {
        if(rangeDates == null || rangeDates == undefined) {
            rangeDates = [];
            let now = new Date();
            now.setDate(now.getDate() + 1);
            let lastWeek = new Date();
            lastWeek.setDate(now.getDate() - 30);
            rangeDates[0] = lastWeek;
            rangeDates[1] = now;
        }
        let d1 = formatDate(rangeDates[0], "yyyy/MM/dd hh:mm:ss", "en_US");
        let d2 = formatDate(rangeDates[1], "yyyy/MM/dd hh:mm:ss", "en_US");
        return this.httpClient.get(`${Config.apiUrl}/actworkflow/summary?filters=createdAt BETWEEN '${d1}' AND '${d2}'&skip=0&limit=0&orderBy=status asc`);
    }

    public increaseRequiredAssignees(workflowId: number) {
        const url = `${Config.apiUrl}/actworkflow/${workflowId}/increaseassignees`;
        console.log(url);

        return this.httpClient.get(url);
        // return this.httpClient.get(url);
    }
}
