import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';

export const Config = {
    production: true,
    // serverUrl: 'https://actiumplusapi.azurewebsites.net',
    // apiUrl: 'https://actiumplusapi.azurewebsites.net/api',
    serverUrl: 'https://api-develop.actiumtechnologie.com',
    apiUrl: 'https://api-develop.actiumtechnologie.com/api',
    tokenType: 'Bearer',
    sseUrl: 'https://api-develop.actiumtechnologie.com/api/sse-notifications',
    reportUrl: 'https://reports-develop.actiumtechnologie.com'
};

export const AdtRxStompConfig: InjectableRxStompConfig = {
    /*
    Which server?
    */
    // brokerURL: 'wss://staging-adt.actiumtechnologie.com:15673/ws',
    brokerURL: 'wss://rabbitmq-develop.actiumtechnologie.com:15673/ws',

    /*
    Headers
    Typical keys: login, passcode, host we must provide non-generic to avoid
    "login failed - access_refused (user must access over loopback)"
    don't forget to change for deployment en prod
    */
    connectHeaders: {
        'login': 'actiumtechnologie',
        'passcode': '$D5!a7Vv8L#&rS',
        'client-id': 'plus-angular'
    },

    /*
    How often to heartbeat?
    Interval in milliseconds, set to 0 to disable
    Incoming typical value 0 - disabled
    Outgoing typical value 20000 - every 20 seconds
    */
    heartbeatIncoming: 0, //
    heartbeatOutgoing: 2000, //

    /*
    Wait in milliseconds before attempting auto reconnect
    Set to 0 to disable
    Typical value 5000 (5 seconds)
    */ 
    reconnectDelay: 200,

    /*
    Will log diagnostics on console
    It can be quite verbose, not recommended in production
    Skip this key to stop logging to console
    */
    // debug: (msg: string): void => { console.log(new Date(), msg); }
};
