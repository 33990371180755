import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workflowStepIcon'
})
export class WorkflowStepIconPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var ret = "";
    switch(value) {
        case null:
            ret = "adt-icon icon-star" //created
            break;
        case "assignment":
            ret = "adt-icon icon-arrow-circle-right" //started
            break;
        case "acceptance":
            ret = "adt-icon icon-hourglass-half" //assigned, waiting for answer
            break;
        case "move":
            ret = "adt-icon icon-shoe-prints" //in transit
            break;
        case "clean":
            ret = "adt-icon icon-broom" //doing the job
            break;
        case "end":
          ret = "adt-icon icon-certificate" //completed
              break;
        default:
            break;
    }
    return ret;
  }

}