// ANGULAR
import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// OTHER
import { TranslateService } from '@ngx-translate/core';
import {InjectableRxStompConfig, RxStompService} from '@stomp/ng2-stompjs';
import { Message, StompHeaders } from '@stomp/stompjs';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

// ACTIUM
import { AuthService } from './services/auth.service';
import { MqService } from './services/mq.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit, OnDestroy {

    public title: string = 'actium-plus';

    // TRANSLATION
    public items: MenuItem[] = [];
    public lblHome: string;
    public lblWorkflows: string;
    public lblInstances: string;
    public lblWorkflowDetails: string;
    public lblRoutes: string;
    public lblBeds: string;
    public lblTasks: string;
    public lblReports: string;
    public lblHelp: string;
    public lblLegend: string;
    public lblAbout: string;
    public lblAdmin: string;
    public lblViewUsers: string;
    public lblAddUsers: string;
    public lblViewGroups: string;
    public lblAddGroups: string;
    public lblViewInfections: string;
    public lblAddInfections: string;
    public lblViewReasons: string;
    public lblAddReasons: string;
    public lblExcel: string;
    public lblAuditCalendar: string = '';
    public lblAuditTable: string = '';

    // AUTH INFORMATION
    public authenticated: boolean = false;
    private isADTUser: boolean = false;
    private isAUDUser: boolean = false;
    private authSubscription: Subscription;

    // RABBIT MQ
    public message: string = '';
    public receivedMessages: string[] = [];
    private topicSubscription: Subscription;
    private binding = '/exchange/JmdExchange/renai.*';
    private subscriptionHeaders: StompHeaders;
    private mqMessageSubscription: Subscription;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private rxStompService: RxStompService,
        private authService: AuthService,
        private mqService: MqService,
        private stompConfig: InjectableRxStompConfig
    ) {
        translate.addLangs(['fr', 'en']);
        translate.setDefaultLang('fr');
        translate.use('fr');
        this.subscriptionHeaders = {
            'id': 'angular',
            'x-queue-name': `angular-${this.getUniqueId(2)}`
        };
        this.mqMessageSubscription = this.mqService.currentMessage.subscribe((message: any) => { this.handleMqMessage(message); });
    }

    ngOnInit(): void {
        this.handleTranslation();
        this.handleAuthSubscriptions();
        this.rxStompService.configure(this.stompConfig);
        this.topicSubscription = this.rxStompService.watch(this.binding, this.subscriptionHeaders).subscribe((message: Message) => {
            this.receivedMessages.push(message.body);
            const messageObject = JSON.parse(message.body);
            this.mqService.dispatchMessage(messageObject);
        });
    }

    /*
    @JM
    Is it important to unsubscribe from observables? My first instinct would be that it would be
    handles by something like a garbage collector. Does that even exist in JS? Of course it does...
    but can it handle observables? If I must unsubscribe manually, I will follow your pattern and
    create a this.audUserSubscription, for example.
    */
    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
        this.topicSubscription.unsubscribe();
    }

    // ***** FUNCTIONS REGARDING AUTH STATUS AND LOGIN/ LOGOUT *****

    /*
    OBSERVABLES. DO. NOT. HAVE. INITIAL. VALUES.
    Subscribing to these observables ensure that when things change, the appropriate
    values are updated. This does not ensure that when the page is loaded this is true.
    We must set this.authenticated "manually".
    */
    handleAuthSubscriptions(): void {
        this.authService.isAuthObservable.subscribe((res: boolean) => {
            this.authenticated = res;
            if (res) this.setItems();
        });

        this.authService.isAUDUserObservable.subscribe((res: boolean) => { this.isAUDUser = res; });
        this.authService.isADTUserObservable.subscribe((res: boolean) => { this.isADTUser = res; });
        this.authenticated = this.authService.isAuthenticated;
        if (this.authenticated) {
            this.determineApplicationUser();
            this.setItems();
        }
    }

    determineApplicationUser(): boolean {
        this.isADTUser = (this.authService.isADTUser);
        this.isAUDUser = (this.authService.isAUDUser);
        return true;
    }

    logout(): void {
        this.authService.logout().subscribe((res: boolean) => {
            this.setItems();
            this.router.navigateByUrl('/auth');
        });
    }

    private handleMqMessage(rabbitMqMessage: any): void {
        if (rabbitMqMessage.msgType === 6) { this.handleForceLogout(rabbitMqMessage); }
    }

    private handleForceLogout(rabbitMqMessage: any): void {
        try {
            if (this.authService.isAuthenticated) {
                const currentUser = this.authService.currentUser;
                if (rabbitMqMessage.Username === currentUser.username) { this.logout(); }
            }
        } catch (error) {
            console.log(error);
        }
    }

    // ***** END OF FUNCTIONS REGARDING AUTH STATUS AND LOGIN/ LOGOUT *****

    // ***** TRANSLATION AND MENU *****

    handleTranslation(): void {
        this.translate.get(['app.menu.home', 'app.menu.workflows', 'app.menu.instances', 'app.menu.workflow-detail',
          'app.menu.routes', 'app.menu.beds', 'app.menu.tasks', 'app.menu.reports', 'app.menu.help', 'app.menu.legend',
          'app.menu.about-us', 'app.menu.admin', 'app.menu.viewUsers', 'app.menu.addUsers', 'app.menu.viewGroups',
          'app.menu.addGroups', 'app.menu.viewInfections', 'app.menu.addInfections', 'app.menu.viewReasons',
          'app.menu.addReasons', 'app.menu.excel', 'app.menu', 'app.menu.auditCalendar', 'app.menu.auditTable'])
            .subscribe((translations: string[]) => {
                this.lblHome = translations['app.menu.home'];
                this.lblWorkflows = translations['app.menu.workflows'];
                this.lblInstances = translations['app.menu.instances'];
                this.lblWorkflowDetails = translations['app.menu.workflow-detail'];
                this.lblRoutes = translations['app.menu.routes'];
                this.lblBeds = translations['app.menu.beds'];
                this.lblTasks = translations['app.menu.tasks'];
                this.lblReports = translations['app.menu.reports'];
                this.lblHelp = translations['app.menu.help'];
                this.lblLegend = translations['app.menu.legend'];
                this.lblAbout = translations['app.menu.about-us'];
                this.lblAdmin = translations['app.menu.admin'];
                this.lblViewUsers = translations['app.menu.viewUsers'];
                this.lblAddUsers = translations['app.menu.addUsers'];
                this.lblViewGroups = translations['app.menu.viewGroups'];
                this.lblAddGroups = translations['app.menu.addGroups'];
                this.lblViewInfections = translations['app.menu.viewInfections'];
                this.lblAddInfections = translations['app.menu.addInfections'];
                this.lblViewReasons = translations['app.menu.viewReasons'];
                this.lblAddReasons = translations['app.menu.addReasons'];
                this.lblExcel = translations['app.menu.excel'];
                this.lblAuditCalendar = translations['app.menu.auditCalendar'];
                this.lblAuditTable = translations['app.menu.auditTable'];

                this.setItems();
            });
    }

    setItems(): void {
        this.items = [];
        this.items = [
            {
                label: this.lblHome,
                visible: this.authenticated,
                icon: 'adt-icon icon-home',
                routerLink: '/'
            },
            {
                label: this.lblWorkflows,
                visible: this.authenticated && this.isADTUser,
                icon: 'adt-icon icon-project-diagram',
                routerLink: '/instances'
            },
            {
                label: this.lblRoutes,
                visible: this.authenticated && this.isADTUser,
                icon: 'adt-icon icon-route',
                routerLink: '/routes'
            },
            {
                label: this.lblBeds,
                visible: this.authenticated && this.isADTUser,
                icon: 'adt-icon icon-bed',
                routerLink: '/beds/grid'
            },
            {
                label: this.lblTasks,
                visible: this.authenticated && this.isADTUser,
                icon: 'adt-icon icon-tasks',
                routerLink: '/tasks'
            },
            {
                label: this.lblReports,
                visible: this.authenticated && (this.isADTUser || this.isAUDUser),
                icon: 'adt-icon icon-chart-line',
                routerLink: '/reports'
            },
            {
                label: this.lblAuditCalendar,
                visible: this.authenticated && this.isAUDUser,
                icon: 'adt-icon icon-calendar',
                routerLink: '/calendar'
            },
            {
                label: this.lblAuditTable,
                visible: this.authenticated && this.isAUDUser,
                icon: 'adt-icon icon-tasks',
                routerLink: '/audit-table'
            },
            {
                label: this.lblAdmin,
                visible: this.authenticated && (this.isADTUser || this.isAUDUser),
                icon: 'pi pi-user-plus',
                items: [
                    { label: this.lblViewUsers, routerLink: '/admin/user-table' },
                    { label: this.lblViewGroups, routerLink: '/admin/group-table' },
                    { label: this.lblViewInfections, routerLink: './admin/infections-table' },
                    { label: 'Interne', routerLink: './admin/internal' },
                    { visible: false, label: this.lblExcel, routerLink: '/admin/excel-upload' }
                ]
            },
            {
                label: this.lblHelp,
                visible: this.authenticated,
                icon: 'adt-icon icon-question-circle',
                routerLink: '/help'
            }
        ];
    }

    // ***** END OF TRANSLATION AND MENU *****

    // ***** HELPER FUNCTIONS *****

    getUniqueId(parts: number): string {
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

    // ***** END OF HELPER FUNCTIONS *****
}
