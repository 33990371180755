import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'auditStatusIcon'
})
export class AuditStatusIconPipe implements PipeTransform {
/*
From audit.ts:

export enum AuditStatus {
    waiting,
    design,
    drawing,
    inspection
}
 */
    transform(value: any, ...args: any[]): any {
        switch(value) {
            case 0:
                return "adt-icon icon-pause"
            case 1:
                return "adt-icon icon-pen"
            case 2:
                return "adt-icon icon-pen"
            case 3: 
                return "adt-icon icon-play"
            default:
                break;
        }
    }

}