// ANGULAR
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// PRIME NG
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { LightboxModule } from 'primeng/lightbox';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { SliderModule } from 'primeng/slider';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { PickListModule } from 'primeng/picklist';

// NGX GRAPH
// import { NgxGraphModule } from '@swimlane/ngx-graph';
import { GojsAngularModule } from 'gojs-angular';

// PIPES
import { WorkflowStatusPipe } from 'src/app/pipes/workflow-status.pipe';
import { NodeStatusColorPipe } from 'src/app/pipes/node-status-color.pipe';
import { NodeStatusPipe } from 'src/app/pipes/node-status.pipe';
import { NodeTypePipe } from 'src/app/pipes/node-type.pipe';
import { TaskStatusPipe } from 'src/app/pipes/task-status.pipe';
import { TaskStatusColorPipe } from 'src/app/pipes/task-status-color.pipe';
import { WorkflowStatusColorPipe } from 'src/app/pipes/workflow-status-color.pipe';
import { WorkflowStatusIconPipe } from 'src/app/pipes/workflow-status-icon.pipe';
import { WorkflowWarningColorPipe } from 'src/app/pipes/workflow-warning-color.pipe';
import { WorkflowWarningIconPipe } from 'src/app/pipes/workflow-warning-icon.pipe';
import { WorkflowStepIconPipe } from 'src/app/pipes/workflow-step-icon.pipe';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { TaskStatusIconPipe } from '../../pipes/task-status-icon.pipe';
import { AuditInspectionTypeIconPipe } from "../../pipes/audit-inspection-type-icon-pipe";
import { AuditStatusIconPipe } from "../../pipes/audit-status-icon-pipe";

// SERVICES
import { WorkflowsService } from 'src/app/services/workflows.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

// SHARED COMPONENTS - OVERLAY PANELS
import { CreateAuditComponent } from '../create-audit/create-audit.component';
import { AdtLoginComponent } from '../adt-login/adt-login.component';
import { WorkflowComponent } from '../workflow/workflow.component';
import { CreateInfectionComponent } from '../admin/create-infection/create-infection.component';
import { CreateUserComponent } from '../admin/create-user/create-user.component';
import { CreateGroupComponent } from '../admin/create-group/create-group.component';
import { EstablishmentComponent } from "../auth/establishment/establishment.component";

// OTHER
import { TranslateModule } from '@ngx-translate/core';
import {AuditReportingService} from "../../services/audit-reporting.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        // PRIME NG
        AccordionModule,
        BadgeModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        ChartModule,
        CheckboxModule,
        DataViewModule,
        DropdownModule,
        DynamicDialogModule,
        InputTextModule,
        LightboxModule,
        MenubarModule,
        MultiSelectModule,
        OverlayPanelModule,
        PanelModule,
        ProgressSpinnerModule,
        TableModule,
        TabViewModule,
        ToastModule,
        TooltipModule,
        TreeTableModule,
        ColorPickerModule,
        ConfirmDialogModule,
        FullCalendarModule,
        SliderModule,
        ContextMenuModule,
        DialogModule,
        ProgressBarModule,
        PickListModule,

        // Ngx Graph
        // NgxGraphModule,
        GojsAngularModule,

        // OTHER
        TranslateModule.forChild()
    ],
    declarations: [
        // SHARED COMPONENTS
        CreateAuditComponent,
        AdtLoginComponent,
        WorkflowComponent,
        CreateInfectionComponent,
        CreateUserComponent,
        CreateGroupComponent,
        EstablishmentComponent,

        // PIPES
        WorkflowStatusPipe,
        NodeStatusColorPipe,
        NodeStatusPipe,
        NodeTypePipe,
        TaskStatusPipe,
        TaskStatusColorPipe,
        WorkflowStatusColorPipe,
        WorkflowStatusIconPipe,
        WorkflowWarningColorPipe,
        WorkflowWarningIconPipe,
        WorkflowStepIconPipe,
        SafePipe,
        TaskStatusIconPipe,
        AuditInspectionTypeIconPipe,
        AuditStatusIconPipe
        // DatePipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        // PRIME NG
        AccordionModule,
        BadgeModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        ChartModule,
        CheckboxModule,
        DataViewModule,
        DropdownModule,
        DynamicDialogModule,
        InputTextModule,
        LightboxModule,
        MenubarModule,
        MultiSelectModule,
        OverlayPanelModule,
        PanelModule,
        ProgressSpinnerModule,
        TableModule,
        TabViewModule,
        ToastModule,
        TooltipModule,
        TreeTableModule,
        ConfirmDialogModule,
        FullCalendarModule,
        PickListModule,

        // NGX GRAPH
        // NgxGraphModule,
        GojsAngularModule,

        // PIPES
        WorkflowStatusPipe,
        NodeStatusColorPipe,
        NodeStatusPipe,
        NodeTypePipe,
        TaskStatusPipe,
        TaskStatusColorPipe,
        WorkflowStatusColorPipe,
        WorkflowStatusIconPipe,
        WorkflowWarningColorPipe,
        WorkflowWarningIconPipe,
        WorkflowStepIconPipe,
        TaskStatusIconPipe,
        AuditInspectionTypeIconPipe,
        AuditStatusIconPipe,
        SafePipe,
    ],
    providers: [
        WorkflowsService,
        AuditReportingService,
        MessageService,
        NodeStatusColorPipe,
        DialogService
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ],
    entryComponents: [
        CreateAuditComponent,
        AdtLoginComponent,
        WorkflowComponent,
        CreateInfectionComponent,
        CreateUserComponent,
        CreateGroupComponent,
        EstablishmentComponent
    ]
})
export class SharedModule { }
